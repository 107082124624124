const state = {
  showSidebar: true,
  showTopbar: true,
  colorDefault: 'green darken-4',
  sidebar: true,
  tableRowsPerPage: [10, 50, { text: 'Todos', value: -1 }],
  rowsPerPageText: 'Linhas por página',
}

const mutations = {
  hideLayout(state) {
    state.showTopbar = false
    state.showSidebar = false
  },
  showLayout(state) {
    state.show = true
    state.showSidebar = true
  },
  showTopbar(state) {
    state.showTopbar = true
    state.showSidebar = false
  },
  showAllLayout(state) {
    state.showTopbar = true
    state.showSidebar = true
    state.show = true
    state.sidebar = true
  },
}

const getters = {
  showTopbar: (state) => state.showTopbar,
  showSidebar: (state) => state.showSidebar,
  colorDefault: (state) => state.colorDefault,
  tableRowsPerPage: (state) => state.tableRowsPerPage,
  rowsPerPageText: (state) => state.rowsPerPageText,
}

const actions = {
  flipflopSidebar({ state }) {
    state.sidebar = !state.sidebar
  },
  openSidebar({ state }) {
    state.sidebar = true
  },
  closeSidebar({ state }, value) {
    state.sidebar = false
  },
  consisteSidebar({ state }, value) {
    state.sidebar = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
