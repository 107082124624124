import axios from '@plugins/axios'

const baseURL = 'certificates'

const state = {
  hasCertificateActive: false,
  certificatesExpired: [],
  certificatesActive: [],
}
const getters = {
  certificatesExpired: (state) => state.certificatesExpired,
  certificatesActive: (state) => state.certificatesActive,
  hasCertificateActive: (state) => state.hasCertificateActive,
}

const mutations = {
  setHasCertificateActive(state, newValue) {
    state.hasCertificateActive = newValue
  },
  setCertificatesActive(state, certificates) {
    state.certificatesActive = certificates
  },
  setCertificatesExpired(state, certificates) {
    state.certificatesExpired = certificates
  },
  setNewCertificate(state, certificate) {
    const newProps = {
      showPassword: false,
      tryingToDownload: false,
      tryingToUpdate: false,
    }
    const newCertificate = { ...certificate, ...newProps }
    if (certificate.isValid) {
      state.certificatesActive.push(newCertificate)
    } else {
      state.certificatesExpired.push(newCertificate)
    }
  },
}

const actions = {
  async getCertificates({ commit }) {
    commit('setCertificatesActive', [])
    commit('setCertificatesExpired', [])
    await axios
      .get(`${baseURL}`)
      .then((res) => {
        for (const certificate of res.data.certificates) {
          commit('setNewCertificate', certificate)
        }
        commit('setHasCertificateActive', res.data.hasCertificateActive)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async createCertificate({ commit }, { file_certificate, password }) {
    return await axios
      .post(`/${baseURL}`, { file_certificate, password })
      .then((res) => {
        commit('setNewCertificate', res.data)
        Promise.resolve(res.data)
      })
      .catch((error) => {
        return Promise.reject()
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
