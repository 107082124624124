import partner from '@src/models/partner'

import axios from '@plugins/axios'

const baseUrl = 'partner'

const state = {
  partner: Object.assign({}, partner),
  partners: [],
  partnerAccountable: {},
  pending: false,
  loading: 0,
  loadingCep: false,
  loadingRg: false,
  queuedData: {},
  queued: false,
}

const getters = {
  partner: (state) => state.partner,
  partners: (state) => state.partners,
  partnerUser: (state) => state.partnerUser,
  partnerAccountable: (state) => state.partnerAccountable,
  partnerLoadingRg: (state) => state.loadingRg,
}

const actions = {
  async getAllPartners({ commit }) {
    await axios
      .get(`${baseUrl}`)
      .then((res) => {
        commit('PARTNERS_SET_ALL', res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async deletePartner({ commit }, id) {
    await axios
      .delete(`${baseUrl}/${id}`)
      .then((res) => {
        commit('DELETE_PARTNER', id)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async savePartnerField({ commit }, data) {
    return await axios
      .put(`${baseUrl}/${data.id}`, data)
      .then((res) => {
        const partner = res.data
        commit('UPDATE_PARTNER', {
          newPartner: partner,
          keys: Object.keys(data),
        })
        return Promise.resolve(partner)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async getPartnerAccountable({ commit }) {
    await axios
      .get(`${baseUrl}/accountable`)
      .then((res) => {
        commit('setPartnerAccountable', res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async createPartner({ commit }, data) {
    return await axios
      .post(`${baseUrl}`, data)
      .then((res) => {
        const partner = res.data
        commit('SET_PARTNER_IN_LIST', partner)
        return Promise.resolve(partner)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  setPartnerLaodingCep({ commit }, value) {
    commit('SET_LOADING_PARTNER_CEP', value)
  },
  async fetchPartner({ commit }, id) {
    return await axios
      .get(`${baseUrl}/${id}`)
      .then((resp) => {
        const partner = resp.data
        commit('SET_PARTNER', partner)
        return Promise.resolve(partner)
      })
      .catch((err) => {
        console.log(err)
      })
  },
}

const mutations = {
  PARTNERS_SET_ALL(state, list) {
    state.partners = list
      .map((partner) => {
        let oldPartner = state.partners.find((item) => item.id === partner.id)
        partner.incomplete = oldPartner ? oldPartner.incomplete : false
        return partner
      })
      .sort(function(a, b) {
        if (a.id > b.id) {
          return 1
        }
        if (a.id < b.id) {
          return -1
        }
        // a must be equal to b
        return 0
      })
  },
  SET_LOADING_PARTNER_CEP(state, newValue) {
    state.loadingCep = newValue
  },
  DELETE_PARTNER(state, id) {
    state.partners.find((partner, index) => {
      if (partner.id === id) {
        state.partners.splice(index, 1)
        return true
      }
    })
  },
  SET_PARTNER_INCOMPLETE(state, id) {
    state.partners.find((partner, index) => {
      if (partner.id === id) {
        partner.incomplete = true
        return true
      }
    })
  },
  SET_LOADING_START_PARTNER_RG(state, newValue) {
    state.loadingRg = newValue
  },
  SET_PARTNER(state, partner) {
    let oldPartner = state.partners.find((item) => item.id === partner.id)
    partner.incomplete = oldPartner ? oldPartner.incomplete : false

    state.partner = partner
  },
  SET_PARTNER_IN_LIST(state, partner) {
    state.partners.push(partner)
  },
  setPartnerRules(state, { name, rule }) {
    state.rules[name] = rule
  },
  UPDATE_PARTNER(state, { newPartner, keys }) {
    keys.forEach((key) => {
      state.partner[key] = newPartner[key]
    })
    state.partners = state.partners.map((item) => {
      if (item.id === newPartner.id) item = newPartner
      return item
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
