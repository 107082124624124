import axios from '@plugins/axios'

const baseURL = 'expense'

const state = {
  loading: false,
  category: {
    loaded: false,
    loading: false,
    data: [],
  },
  expenses: [],
}

const getters = {
  expenses: (state) => state.expenses,
}

const actions = {
  async getExpenses({ commit }) {
    await axios
      .get(`${baseURL}`)
      .then((res) => {
        commit('setAll', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getExpenseCategory({ state, commit }) {
    state.category.loading = true
    await axios
      .get(`${baseURL}-category`)
      .then((resp) => {
        commit('setCategory', resp.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async createNewExpense({ state, commit }, data) {
    return await axios
      .post(`${baseURL}`, data)
      .then((res) => {
        commit('addExpense', res.data)
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  async updateExpense({ state, commit }, data) {
    // state.loading = true
    await axios
      .put(`${baseURL}/${data.id}`, data)
      .then((res) => {
        commit('updateExpense', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async deletedExpense({ state, commit }, id) {
    state.loading = true
    await axios
      .delete(`${baseURL}/${id}`)
      .then((res) => {
        commit('deleteExpense', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

const mutations = {
  setCategory(state, data) {
    state.category.loading = false
    state.category.data = data
  },
  setAll(state, data) {
    state.expenses = data
  },
  updateExpense(state, expense) {
    state.loading = false
    const current = state.expenses.map((item) => {
      if (expense.id === item.id) {
        item = expense
      }
      return item
    })
    state.expenses = []
    state.expenses = current
  },
  updateProvider(state, provider) {
    for (const expense of state.expenses) {
      if (expense.provider.id == provider.id) {
        expense.provider = provider
      }
    }
  },
  deleteExpense(state, expense) {
    state.loading = false
    const current = state.expenses.filter((item) => expense.id !== item.id)
    state.expenses = current
  },
  addExpense(state, expense) {
    state.loading = false
    state.expenses.push(expense)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
