const state = {
  drawer: true,
  mini: false,
  mobileBreakPoint: 600,
}

const mutations = {
  toggleMenu(state) {
    state.drawer = !state.drawer
  },
}

const getters = {
  drawer: (state) => state.drawer,
  mobileBreakPoint: (state) => state.mobileBreakPoint,
}

const actions = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
