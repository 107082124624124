<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        label="Valor"
        required
        v-model="valueLocal"
        v-model.lazy="valueLocal"
        v-money="money"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { VMoney } from 'v-money'
export default {
  props: {
    value: {
      required: true,
    },
    // rules: {
    //   type: Array,
    //   default: () => [],
    //   required: false,
    // },
  },
  directives: {
    money: VMoney,
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: ' ',
        precision: 2,
        masked: true /* doesn't work with directive */,
      },
      rules: {
        value: [
          (v) =>
            !(this.$options.filters.currencyToCents(v) <= 0) ||
            'O valor deve ser maior que 0.',
        ],
      },
    }
  },

  computed: {
    valueLocal: {
      get: function() {
        return this.value
      },
      set: function(value) {
        this.$emit('input', value)
        // this.$emit('input', value.replace(/\D/g, ''))
      },
    },
  },
}
</script>
