if (typeof Iugu !== 'undefined') {
  Iugu.setAccountID(process.env.VUE_APP_IUGU_ACCOUNT_ID)
  if (process.env.VUE_APP_BUILD_TARGET !== 'production') Iugu.setTestMode(true)
} else {
  const Iugu = {}
}

function _generateToken(form) {
  return new Promise((resolve) => {
    Iugu.createPaymentToken(form, (resp) => resolve(resp))
  })
}
export default {
  validateCvv(creditcard, cvv) {
    const brand = this.getBrandByCreditCardNumber(creditcard)
    return Iugu.utils.validateCVV(cvv, brand) // Retorna true
  },
  validateNumber(creditcard) {
    return Iugu.utils.getBrandByCreditCardNumber(creditcard)
  },
  validateExpiration(exp) {
    return Iugu.utils.validateExpirationString(exp)
  },
  async createCreditcardToken(form) {
    return await _generateToken(form)
  },
  getBrandByCreditCardNumber(creditcard) {
    if (!creditcard) return false
    return Iugu.utils.getBrandByCreditCardNumber(creditcard)
  },
  validateExpirationByMonthAndYear({ month, year }) {
    return Iugu.utils.validateExpiration(month, year)
  },
  objectCreditCard({ number, month, year, firstName, lastName, cvv }) {
    return Iugu.CreditCard(number, month, year, firstName, lastName, cvv)
  },
}
