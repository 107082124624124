import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import bankSlip from '@components/svg/bank-slip.vue'
import simpleNational from '@components/png/simple-national.vue'
import registeredSaoPaulo from '@components/png/registered-sao-paulo.vue'
import serviceProvider from '@components/png/service-provider.vue'
import noEmployer from '@components/png/no-employer.vue'
import elo from '@components/svg/elo.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },

    // themes: {
    //   light: {
    //     primary: '#ee44aa',
    //     secondary: '#424242',
    //     accent: '#82B1FF',
    //     error: '#FF5252',
    //     info: '#2196F3',
    //     success: '#4CAF50',
    //     warning: '#FFC107',
    //   },
    // },
  },
  icons: {
    iconfont: 'md',
    values: {
      bankSlip: {
        component: bankSlip,
        props: {
          name: 'boleto',
          color: 'success',
        },
      },
      elo: {
        component: elo,
        props: {
          name: 'boleto',
          color: 'success',
        },
      },
      simpleNational: {
        component: simpleNational,
      },
      registeredSaoPaulo: {
        component: registeredSaoPaulo,
      },
      serviceProvider: {
        component: serviceProvider,
      },
      noEmployer: {
        component: noEmployer,
      },
    },
  },
})
