import axios from '@plugins/axios'

const baseURL = 'scheduling-call'

const state = {
  schedulingCall: {
    id: '',
    phone: null,
    reserved: '',
    period: null,
  },
}

const getters = {
  schedulingCall: (state) => state.schedulingCall,
}

const actions = {
  async createSchedulingCall({ commit }, data) {
    await axios
      .post(`${baseURL}`, data)
      .then((res) => {
        commit('setSchedulingCall', res.data.schedulingCall)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getSchedulingCall({ commit }) {
    await axios
      .get(`${baseURL}`)
      .then((res) => {
        commit('setSchedulingCall', res.data.schedulingCall)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

const mutations = {
  setSchedulingCall(state, newValue) {
    state.schedulingCall = newValue
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
