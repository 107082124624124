import lazyLoadView from './helper'

const newUser = () =>
  lazyLoadView(import('@src/views/newRegistering/form/newUser/newUser.vue'))
const error = () =>
  lazyLoadView(import('@src/views/newRegistering/form/finishingQuiz/error.vue'))
const success = () =>
  lazyLoadView(
    import('@src/views/newRegistering/form/finishingQuiz/success.vue')
  )
const resume = () =>
  lazyLoadView(import('@src/views/newRegistering/form/resume/resume.vue'))
const additionalInfoForm = () =>
  lazyLoadView(
    import(
      '@src/views/newRegistering/form/additionalInfoForm/additionalInfoForm.vue'
    )
  )
const company = () =>
  lazyLoadView(
    import(
      '@src/views/newRegistering/form/additionalInfoForm/company/Company.vue'
    )
  )
const partner = () =>
  lazyLoadView(
    import(
      '@src/views/newRegistering/form/additionalInfoForm/partner/partner.vue'
    )
  )
const ProgressAdmin = () =>
  lazyLoadView(
    import('@src/views/newRegistering/form/progressAdmin/ProgressAdmin.vue')
  )
const Call = () =>
  lazyLoadView(import('@src/views/newRegistering/form/call/call.vue'))

export default {
  path: '/new-registering',
  component: () =>
    lazyLoadView(import('@src/views/newRegistering/newRegistering.vue')),
  children: [
    {
      name: 'check-in',
      path: '',
      component: () =>
        lazyLoadView(
          import('@src/views/newRegistering/form/baseRegister/baseRegister.vue')
        ),
    },
    {
      name: 'quiz',
      path: 'questionario',
      component: () =>
        lazyLoadView(import('@src/views/newRegistering/form/quiz.vue')),
      meta: {
        authRequired: true,
        allowedSteps: [1],
      },
    },
    {
      name: 'finishingQuiz',
      path: 'finishing-quiz',
      component: success,
      meta: {
        authRequired: true,
        allowedSteps: [1],
      },
    },
    {
      name: 'doesNotFit',
      path: 'does-not-fit',
      component: error,
    },
    {
      name: 'newUser',
      path: 'new-user',
      component: newUser,
      meta: {
        authRequired: true,
        allowedSteps: [1],
      },
    },
    {
      name: 'resume',
      path: 'resume',
      component: resume,
      meta: {
        authRequired: true,
        allowedSteps: [1],
      },
    },
    {
      name: 'payment',
      path: 'payment',
      component: () =>
        lazyLoadView(
          import('@src/views/newRegistering/form/payment/payment.vue')
        ),
      meta: {
        authRequired: true,
        allowedSteps: [1],
      },
    },
    {
      name: 'pending-payment',
      path: 'payment/pending-payment',
      component: () =>
        lazyLoadView(
          import('@src/views/newRegistering/form/payment/PaymentPending.vue')
        ),
      meta: {
        authRequired: true,
        allowedSteps: [1],
      },
    },
    {
      path: 'additional-info-form',
      component: additionalInfoForm,
      children: [
        {
          path: '',
          name: 'additionalInfoForm',
          component: company,
          meta: {
            authRequired: true,
            allowedSteps: [1],
          },
        },
        {
          name: 'partner',
          path: 'partner/:id',
          component: partner,
          meta: {
            authRequired: true,
            allowedSteps: [1],
          },
        },
        {
          name: 'company',
          path: 'company',
          component: company,
          meta: {
            authRequired: true,
            allowedSteps: [1],
          },
        },
      ],
    },
    {
      name: 'progressAdmin',
      path: 'progress-admin',
      component: ProgressAdmin,
      meta: {
        authRequired: true,
        allowedSteps: [1],
      },
    },
    {
      name: 'call',
      path: 'call',
      component: Call,
      meta: {
        authRequired: true,
        allowedSteps: [1],
      },
    },
  ],
}
