import Vue from 'vue'

import axios from '@plugins/axios'

const state = {
  invoices: [],
}

const getters = {
  invoices: (state) => state.invoices,
}

const actions = {
  async getInvoices({ commit }) {
    await axios
      .get(`invoice`)
      .then((res) => commit('setInvoices', res.data))
      .catch((err) => {
        console.log(err)
      })
  },
}

const mutations = {
  setInvoices(state, invoices) {
    state.invoices = invoices
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
