<template>
	<v-img :src="path"></v-img>
</template>
<script>
import simpleNational from '@src/assets/images/icons/serves/icone-empresas-do-simples-nacional.png'
export default {
	data() {
		return {
			path: simpleNational
		}
	},
}
</script>