import lazyLoadView from './helper'

const Error = () => lazyLoadView(import('@src/views/errors/Index.vue'))
const PageNotFound = () =>
  lazyLoadView(import('@src/views/errors/PageNotFound.vue'))
const Forbiden = () => lazyLoadView(import('@src/views/errors/Forbiden.vue'))

export default {
  path: '/error',
  component: Error,
  children: [
    {
      path: 'page-not-found',
      name: 'page-not-found',
      component: PageNotFound,
    },
    {
      name: 'forbiden',
      path: 'forbiden',
      component: Forbiden,
    },
  ],
}
