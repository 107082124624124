export default {
  accountable: false,
  accountUser: false, // If this partner is the user account which is createing the company on sys
  address: null,
  addressComplement: null,
  addressNumber: null,
  cepNotFound: false, // If not found then user can write his addres data
  civilStatus: null,
  cpf: null,
  district: null,
  email: null,
  fullName: null,
  gender: null,
  id: null,
  idCity: null,
  idMatrimonialRegime: null,
  idState: null,
  incomeTaxDeclaration: null,
  incomeTaxDeclarationYear: null,
  incomplete: false, // Local Check if cad is incomplete
  noDeclaration: false,
  noIncomeTaxDeclaration: false,
  occupation: null,
  percentageHolding: null,
  phone: null,
  rg: null,
  cep: null,
}
