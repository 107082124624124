const DEFAULT_TIMEOUT = 6000
const DEFAULT_COLOR = 'error'
const DEFAULT_MODE = null
const DEFAULT_MESSAGE = null

const state = {
  timeout: DEFAULT_TIMEOUT,
  color: DEFAULT_COLOR,
  mode: DEFAULT_MODE,
  message: DEFAULT_MESSAGE,
  active: false,
}

const getters = {
  snackbarErrorsTimeout: (state) => state.timeout,
  snackbarErrorsColor: (state) => state.color,
  snackbarErrorsMode: (state) => state.mode,
  snackbarErrorsActive: (state) => state.active,
  snackbarErrorsMessage: (state) => state.message,
}

const mutations = {
  CLOSE_SNACKBAR_ERRORS(state) {
    state.active = false
  },
  OPEN_SNACKBAR_ERRORS(state, message) {
    state.message = message
    state.active = true
  },
}

const actions = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
