<template>
  <v-app-bar
    color="#f8f8f8"
    dense
    elevate-on-scroll
    height="75"
    app
    clipped-left
  >
    <v-row justify="center" no-gutters>
      <v-col cols="12" sm="12" md="2" class="d-flex justify-center pa-0 ma-0">
        <img
          :src="logoTagline"
          style="
              max-height:200px;
              max-width:200px;
              height:100%;
              width:100%;
            "
        />
      </v-col>
    </v-row>
    <div v-if="logout" class="text-center">
      <v-btn
        @click="navigateTo()"
        class="subheading custom-btn-green-inverse rounded-0"
      >
        <!-- style="background-color: #0080D1;" -->
        <!-- <v-icon small>arrow_forward</v-icon> -->
        sair</v-btn
      >
    </div>
  </v-app-bar>
</template>
<script>
import logoTagline from '@src/assets/images/logo/logo-tagline.svg'

export default {
  props: {
    logout: {
      type: Boolean,
      defalt: () => {
        return false
      },
    },
  },
  data() {
    return {
      logoTagline,
    }
  },
  methods: {
    navigateTo() {
      this.$router.push({ name: 'logout' })
    },
  },
}
</script>
<style lang="scss" module>
@import '@design';
</style>
