import axios from '@plugins/axios'

const baseURL = 'cep'

const state = {
  mask: '#####-###',
  rules: [
    (v) => !!v || 'Por favor preencha o CEP.',
    (v) => {
      v += ''
      return v.length === 9 || 'Por favor preencha com um CEP válido.'
    },
  ],
  address: {},
  laoding: false,
}

const getters = {}

const actions = {
  async search({ commit }, cep) {
    return await axios
      .get(`${baseURL}/${cep}`)
      .then((res) => {
        const address = res.data
        commit('setAddress', address)
        return Promise.resolve(address)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  },
}

const mutations = {
  setAddress(state, newValue) {
    state.address = newValue
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
