const state = {
  title: 'topbar',
}

const mutations = {
  updateTitle(state, title) {
    state.title = title
  },
}

const getters = {
  title: (state) => state.title,
}

const actions = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
