/**
 * Append gtag script to DOM
 * @param  {String} trackId GA_TRACKING_ID
 * @param  {String} id      script id
 */
function appendGtagScript(trackId, id) {
  var s = document.createElement('script')
  s.id = id
  s.async = true
  s.src = `https://www.googletagmanager.com/gtag/js?id=${trackId}`
  var n = document.getElementsByTagName('head')[0]
  if (!n) n = document.body
  n.appendChild(s)
}

/**
 * initialize gtag
 * @param  {String} trackId GA_TRACKING_ID
 * @param  {Object} opts  {debug,scriptId}
 */
export function initialize(trackId, { ative }) {
  if (!ative) return () => {}
  if (window.gtag) return
  appendGtagScript(trackId, 'gtagjs')
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  let gtagOpt = {}
  gtag('js', new Date())
  gtag('config', trackId, gtagOpt)

  return gtag
}
