import axios from '@plugins/axios'

const state = {
  company: {},
  companyIncomplete: false,
  contracts: [],
  alerts: [], // { type: string, info: string }
  annualDeclaration: [],
  laodingCep: false,
  loadingContratoSocial: false,
  loadingIptu: false,
}

const getters = {
  company: (state) => state.company,
  alerts: (state) => state.alerts,
  companyIncomplete: (state) => state.companyIncomplete,
  laodingCep: (state) => state.laodingCep,
  loadingContratoSocial: (state) => state.loadingContratoSocial,
  loadingIptu: (state) => state.loadingIptu,
}

const actions = {
  async getCompany({ commit }) {
    await axios
      .get(`company-data`)
      .then((res) => {
        commit('setCompany', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getContracts({ commit }) {
    await axios
      .get(`contracts/social`)
      .then((res) => {
        commit('setContracts', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getAnnualDeclaration({ commit }) {
    await axios
      .get(`annual-declaration`)
      .then((res) => commit('setAnnualDeclaration', res.data))
      .catch((error) => {
        console.log(error)
      })
  },
  async updateCompany({ state, commit }, data) {
    await axios
      .put(`company/${state.company.id}`, data)
      .then((res) => {
        commit('updateCompany', { res: res.data, dataSend: data })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getCurrentCompany({ state, commit }) {
    await axios
      .get(`company`)
      .then((res) => {
        commit('setCompany', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  setLaodingCep({ commit }, newValue) {
    commit('setLaodingCep', newValue)
  },
}

const mutations = {
  setCompany(state, company) {
    state.company = company
  },
  setContracts(state, contracts) {
    state.contracts = contracts
  },
  addAlerts(state, alerts) {
    state.alerts.push(alerts)
  },
  setAnnualDeclaration(state, annualDeclaration) {
    state.annualDeclaration = annualDeclaration
  },
  updateCompany(state, { res, dataSend }) {
    Object.keys(dataSend).map((key, index) => {
      state.company[key] = res[key]
    })
  },
  setIncomplete(state, v) {
    state.companyIncomplete = v
  },
  setLaodingCep(state, newValue) {
    state.laodingCep = newValue
  },
  setLoadingContratoSocial(state, newValue) {
    state.loadingContratoSocial = newValue
  },
  setLoadingIptu(state, newValue) {
    state.loadingIptu = newValue
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
