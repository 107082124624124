import Cookies from 'js-cookie'

const cookieName = 'sourceURLs'
const conf = { domain: process.env.VUE_APP_COOKIE_DOMAIN }

const getUrls = () => {
  return Cookies.get(cookieName)
    ? JSON.parse(Cookies.get(cookieName), true)
    : []
}

export default {
  set() {
    const fullPath = window.location.href
    let urls = getUrls()
    let hasUrl = urls.find((url) => {
      url === fullPath
    })
    if (!hasUrl) urls.push(fullPath)
    Cookies.set(cookieName, JSON.stringify(urls), conf)
  },
  get() {
    return getUrls()
  },
}
