import axios from '@plugins/axios'

const baseURL = 'back-office'

const state = {
  progress: null,
}

const getters = {
  progress: (state) => state.progress,
}

const actions = {
  async getBackOfficeProgress({ commit }) {
    await axios
      .get(`${baseURL}/progress`)
      .then((res) => commit('setProgress', res.data))
      .catch((err) => console.log(err))
  },
}

const mutations = {
  setProgress(state, data) {
    let donePercentage
    let tot = data.qtdTasks
    const done = data.qtdFinish

    tot = tot < 1 ? 1 : tot
    donePercentage = (100 * done) / tot
    state.progress = Math.floor(donePercentage)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
