import store from '@state/store'
import routes from './routes'
import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress'
NProgress.configure({ showSpinner: true })
Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.configure({
      showSpinner: false,
      trickleSpeed: 1,
      speed: 900,
    })
    NProgress.start()
  }
  if (routeTo.query.token) {
    await store.commit('auth/SET_TOKEN', { accessToken: routeTo.query.token })
    await store.dispatch('auth/fetchUser')
  }
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  let allowedSteps = []
  routeTo.matched.forEach((route) => {
    if (Object.prototype.hasOwnProperty.call(route.meta, 'allowedSteps')) {
      allowedSteps = route.meta.allowedSteps
    }
  })

  // If auth isn't required for the route, just continue.
  if (!authRequired) {
    return next()
  }

  // If auth is required and the user is logged in...
  if (store.getters['auth/loggedIn']) {
    // Validate the local user token...
    return store
      .dispatch('auth/validate')
      .then((validUser) => {
        //check user
        if (!validUser) return redirectToLogin()

        if (validUser.idRegistrationStatus === 1) {
          return redirectFormRegistration(validUser)
        }
        if (allowedSteps.includes(1)) {
          return redirectToHome()
        }

        if (allowedSteps.includes(validUser.idRegistrationStatus)) {
          return redirectToHome()
        }
        // Then continue if the token still represents a valid user,
        // otherwise redirect to login.
        return next()
      })
      .catch((error) => {
        return redirectToLogin()
      })
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }

  async function redirectFormRegistration(user) {
    const routerQuizNext = async () => {
      const userFrom = await store.dispatch('formDataUser/getFormUser')
      switch (userFrom.registrationStatus.value) {
        case 1:
          return 'quiz'
        case 2:
          return 'finishingQuiz'
        case 3:
          return 'doesNotFit'
        case 4:
          return 'newUser'
      }
    }
    const responseNext = (name) => {
      return routeTo.name != name ? next({ name }) : next()
    }
    let routeToName = ''
    switch (user.idStepRegistration) {
      case 1:
        routeToName = await routerQuizNext()
        break
      case 2:
        routeToName = 'resume'
        break
      case 3:
        routeToName = 'payment'
        break
      case 4:
        const testGoTo = ['additionalInfoForm', 'partner', 'company']
        routeToName = testGoTo.includes(routeTo.name)
          ? routeTo.name
          : 'additionalInfoForm'
        break
      case 5:
        routeToName = 'call'
        break
      case 6:
      case 7:
      case 8:
        routeToName = 'progressAdmin'
        break
      default:
        responseNext = 'home'
        break
    }
    // console.log(routeToName, responseNext(routeToName))
    return responseNext(routeToName)
  }
  function redirectToHome() {
    const routersRegistration = [
      'quiz',
      'finishingQuiz',
      'doesNotFit',
      'newUser',
      'resume',
      'payment',
      'additionalInfoForm',
      'partner',
      'company',
      'call',
      'progressAdmin',
    ]
    if (routersRegistration.includes(routeTo.name)) {
      return next({ name: 'home' })
    }
    return next()
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              if (args) {
                next(...args)
              } else {
                next()
              }
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
