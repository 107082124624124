import axios from '@plugins/axios'

const TabItemsModule = {
  name: null,
  id: null,
  icon: 'face',
  goTo: { name: null, params: { id: null } },
  incomplete: false,
}
const itemTabCompany = {
  name: 'dados da empresa',
  id: -1,
  icon: 'store',
  goTo: { name: 'company' },
  incomplete: false,
}

const partnerIsValid = (validPartner) => {
  return (
    !!validPartner.isValidCep &&
    !!validPartner.isValidAddressNumber &&
    !!validPartner.isValidStreet &&
    !!validPartner.isValidState &&
    !!validPartner.isValidCity &&
    !!validPartner.isValidFullName &&
    !!validPartner.isValidEmail &&
    !!validPartner.isValidOccupation &&
    !!validPartner.isValidPercentageHolding &&
    !!validPartner.isValidGender &&
    !!validPartner.isValidCivilStatus &&
    !!validPartner.isValidRg &&
    !!validPartner.isValidTaxDeclaration &&
    !!validPartner.isValidTaxDeclarationSecond &&
    !!validPartner.isValidVoterRegistration &&
    !!validPartner.isValidMatrimonialRegime
  )
}

const state = {
  tabItems: [],
  validPartners: [],
  validCompany: null,
  forceValidationCompany: false,
  forceValidationPartners: false,
  allPartnersIsValid: true,
  companyIsValid: true,
}

const getters = {
  tabItems: (state) => state.tabItems,
  forceValidationCompany: (state) => state.forceValidationCompany,
  forceValidationPartners: (state) => state.forceValidationPartners,
  allPartnersIsValid: (state) => state.allPartnersIsValid,
  companyIsValid: (state) => state.companyIsValid,
}

const mutations = {
  setTabItems(state, validPartners) {
    let TEMP_LIST = []
    state.tabItems = []
    TEMP_LIST.push(
      Object.assign(itemTabCompany, {
        incomplete: !state.companyIsValid,
      })
    )
    for (const item of validPartners) {
      let newPartnerItem = Object.assign({}, TabItemsModule)
      newPartnerItem.id = item.partnerId
      newPartnerItem.name = item.fullName?.split(' ')[0] || 'Novo(a) Sócio(a)'
      newPartnerItem.goTo = { name: 'partner', params: { id: item.partnerId } }
      newPartnerItem.incomplete = state.forceValidationPartners
        ? !partnerIsValid(item)
        : false
      TEMP_LIST.push(newPartnerItem)
    }

    state.tabItems = TEMP_LIST.sort(function(a, b) {
      if (a.id > b.id) {
        return 1
      }
      if (a.id < b.id) {
        return -1
      }
      // a must be equal to b
      return 0
    })
  },
  setValidPartners(state, newData) {
    state.validPartners = newData
  },
  setValidCompany(state, newData) {
    state.validCompany = newData
  },
  newTabItemPartner(state, id) {
    let newPartnerItem = Object.assign({}, TabItemsModule)
    newPartnerItem.id = id
    newPartnerItem.name = 'Novo(a) Sócio(a)'
    newPartnerItem.goTo = { name: 'partner', params: { id } }
    state.tabItems.push(newPartnerItem)
  },
  setForceValidationPartners(state, validPartner) {
    state.forceValidationPartners = true
    state.tabItems.map((item) => {
      if (item.id === validPartner.partnerId) {
        let isComplite = partnerIsValid(validPartner)
        item.incomplete = !isComplite
      }
      if (item.incomplete) state.allPartnersIsValid = false
    })
  },
  setForceValidationCompany(state, validCompany) {
    state.forceValidationCompany = true
    state.tabItems.map((item) => {
      if (item.id === -1) {
        let incomplete =
          validCompany.isValidCep &&
          validCompany.isValidAddressNumber &&
          validCompany.isValidStreet &&
          validCompany.isValidState &&
          validCompany.isValidCity &&
          validCompany.isValidCnpj &&
          validCompany.isValidDigitalCertificate &&
          validCompany.isValidCityHallPassword &&
          validCompany.isValidIptu &&
          validCompany.isValidSociaContract
        item.incomplete = !incomplete
        state.companyIsValid = incomplete
      }
    })
  },
  setAllPartnersIsValid(state, newValue) {
    state.allPartnersIsValid = newValue
  },
}

const actions = {
  async getValidations({ commit, dispatch }) {
    const { validPartners, validCompany } = await dispatch(
      'getValidationsWithoutSetMutations'
    )
    commit('setTabItems', validPartners)
    commit('setValidPartners', validPartners)
    commit('setValidCompany', validCompany)
    return Promise.resolve({ validPartners, validCompany })
  },
  async forceValidation({ commit, dispatch, state }) {
    const { validPartners, validCompany } = await dispatch(
      'getValidationsWithoutSetMutations'
    )
    commit('setForceValidationCompany', validCompany)
    commit('setAllPartnersIsValid', true)
    for (const validPartner of validPartners) {
      commit('setForceValidationPartners', validPartner)
    }
    return Promise.resolve({ validationPartners: state.ValidationPartners })
  },
  getValidationsWithoutSetMutations({ commit }) {
    return axios
      .get('validation-form-company-and-partners')
      .then((resp) => {
        const { validPartners, validCompany } = resp.data
        return Promise.resolve({ validPartners, validCompany })
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  checkIfPartnerIsValidById({ state }, id) {
    const validPartner = state.validPartners.find(
      (partner) => partner.id === id
    )
    return partnerIsValid(validPartner)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
