import store from '@state/store'
import lazyLoadView from './helper'

const Box = () => lazyLoadView(import('@src/views/login/Box.vue'))
const Enter = () => lazyLoadView(import('@src/views/login/Enter.vue'))
const Reset = () => lazyLoadView(import('@src/views/login/Reset.vue'))
const Recover = () => lazyLoadView(import('@src/views/login/Recover.vue'))
const Cancelled = () => lazyLoadView(import('@src/views/login/Cancelled.vue'))
const EmailConfirm = () => lazyLoadView(import('@src/views/EmailConfirm.vue'))

export default {
  path: '/login',
  component: Box,
  children: [
    {
      path: '',
      name: 'login',
      component: Enter,
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          // If the user is already logged in
          localStorage.clear()
          store.dispatch('auth/logOut')
          next()
        },
      },
    },
    {
      name: 'logout',
      path: 'logout',
      component: Enter,
      meta: {
        authRequired: false,
        async beforeResolve(routeTo, routeFrom, next) {
          await store.dispatch('auth/logOut')
          const authRequiredOnPreviousRoute = routeFrom.matched.some(
            (route) => route.meta.authRequired
          )
          // Navigate back to previous page, or home as a fallback
          next(
            authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom }
          )
        },
      },
    },
    {
      name: 'login-reset',
      path: 'reset/:token/:emailB64',
      props: true,
      component: Reset,
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          store.dispatch('auth/logOut')
          // Navigate back to previous page, or home as a fallback
          next()
        },
      },
    },
    {
      name: 'login-recover',
      path: 'recover',
      component: Recover,
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          store.dispatch('auth/logOut')
          // Navigate back to previous page, or home as a fallback
          next()
        },
      },
    },
    {
      name: 'cancelled',
      path: 'cancelled',
      component: Cancelled,
      meta: {
        authRequired: true,
      },
    },
    {
      name: 'email-confirm',
      path: 'email-confirm/:token/:emailB64',
      props: true,
      component: EmailConfirm,
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          store.dispatch('auth/logOut')
          // Navigate back to previous page, or home as a fallback
          next()
        },
      },
    },
  ],
}
