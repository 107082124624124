import axios from '@plugins/axios'
import sourceURLs from '@src/services/sourceURLs'

const baseURL = 'user'

export const USER_REGISTRATION_STEP_REGISTER = 1
export const USER_REGISTRATION_STEP_PAYMENT_SUMMARY = 2
export const USER_REGISTRATION_STEP_PAYMENT = 3
export const USER_REGISTRATION_STEP_BASIC_INFORMATION = 4
export const USER_REGISTRATION_STEP_CALL = 5
export const USER_REGISTRATION_STEP_ANALIZE = 6
export const USER_REGISTRATION_STEP_WORKING = 7

export const state = {
  currentUser: getSavedState('currentUser'),
  token: getSavedState('token'),
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('currentUser', state.currentUser)
    setDefaultAuthHeaders(state)
  },
  SET_TOKEN(state, newValue) {
    state.token = newValue
    saveState('token', state.token)
    setDefaultAuthHeaders(state)
  },
  UPDATE_USER(state, { newUser, keys }) {
    keys.forEach((key) => {
      state.currentUser[key] = newUser[key]
    })
    saveState('currentUser', state.currentUser)
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
  userIsSuspended: (state) => {
    return !!state.currentUser
      ? state.currentUser.registration === 'suspended'
      : false
  },
  userIsCanceled: (state) => {
    return !!state.currentUser
      ? state.currentUser.registration === 'canceled'
      : false
  },
  idStepRegistration: (state) => {
    return !!state.currentUser ? state.currentUser.idStepRegistration : null
  },
  userRegistration: (state) => {
    return !!state.currentUser ? state.currentUser.registration : null
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    setDefaultAuthHeaders(state)
    dispatch('validate')
  },

  // Logs in the current user.
  async logIn({ commit, dispatch, getters }, { username, password }) {
    if (getters.loggedIn) return dispatch('validate')
    try {
      const token = await axios
        .post('login', { username, password })
        .then((response) => {
          return response.data
        })
      if (!token) return Promise.reject(false)
      commit('SET_TOKEN', token)
      const user = await dispatch('fetchUser')
      return Promise.resolve(user)
    } catch (error) {
      return Promise.reject(false)
    }
  },

  // Logs out the current user.
  logOut({ commit, dispatch }) {
    commit('SET_CURRENT_USER', null)
    commit('SET_TOKEN', null)
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  async validate({ commit, state, dispatch }) {
    if (!state.token) return Promise.resolve(false)
    if (!state.currentUser) return Promise.resolve(false)
    const user = await dispatch('fetchUser')
    return Promise.resolve(user)
  },
  fetchUser({ commit }) {
    return axios
      .get('user')
      .then((response) => {
        const user = response.data
        commit('SET_CURRENT_USER', user)
        return Promise.resolve(user)
      })
      .catch((error) => {
        commit('SET_CURRENT_USER', null)
        commit('SET_TOKEN', null)
        return Promise.reject(error)
      })
  },
  async checkStepRegistration({ state }, step) {
    if (state.currentUser) {
      return Promise.resolve(state.currentUser.idStepRegistration === step)
    }
    return Promise.resolve(false)
  },
  async createUser(
    { state, commit, dispatch },
    { name, email, partnershipId, coupon, phone }
  ) {
    const data = { name, email, phone }
    if (partnershipId) data.partnershipId = partnershipId
    if (coupon) data.coupon = coupon
    data.sourceUrl = sourceURLs.get()
    return axios
      .post('/form-registering', data)
      .then(async (resp) => {
        const formUser = resp.data.user
        const accessToken = resp.data.accessToken
        commit('formDataUser/setFormUser', formUser, { root: true })
        commit('SET_TOKEN', { accessToken })
        setDefaultAuthHeaders(state)
        await dispatch('fetchUser')
        return Promise.resolve(formUser)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          commit('formDataUser/setFormUser', {}, { root: true })
          commit('SET_CURRENT_USER', null)
          commit('SET_TOKEN', null)
        } else {
          console.warn(error)
        }
        return Promise.reject(error.response.data)
      })
  },
  async updateUser({ commit }, data) {
    await axios
      .put(`${baseURL}`, data)
      .then((res) => {
        const user = res.data
        commit('UPDATE_USER', { newUser: user, keys: Object.keys(data) })
        return Promise.resolve(user)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  },
  async setFirstLogin({ commit, state }) {
    await axios
      .put(`${baseURL}/set-first-login/`)
      .then((res) => {
        const user = res.data
        commit('SET_CURRENT_USER', user)
        return Promise.resolve(user)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  creationLatestsUserInformation(
    { commit },
    { name, email, phone, policy, password }
  ) {
    return axios
      .post('/user-new', { name, email, phone, policy, password })
      .then((resp) => {
        const user = resp.data
        commit('SET_CURRENT_USER', user)
        return Promise.resolve(user)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  passwordRequestResent({ commit }, { email }) {
    email = email.toLowerCase().trim()
    return axios
      .post('password/request', { email })
      .then((resp) => {
        const password = resp.data
        return Promise.resolve(password)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  passwordReset({ commit }, { token, email, password, passwordConfirmation }) {
    return axios
      .post('password/reset', { token, email, password, passwordConfirmation })
      .then((resp) => {
        const password = resp.data
        return Promise.resolve(password)
      })
      .catch((error) => {
        console.log({ error })
        return Promise.reject(error)
      })
  },
}

// ===
// Private helpers
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.token
    ? `Bearer ${state.token.accessToken}`
    : ''
}
