import Vue from 'vue'
import vuetify from '@plugins/vuetify'
import VueClipboard from 'vue-clipboard2'

import VueAnalytics from 'vue-analytics'

import VueYoutube from 'vue-youtube'

import { initialize } from '@src/services/googleGtag'
import translator from './translator'
import App from './App.vue'

import router from './router'
import store from '@state/store'

import vueMask from '@plugins/vueMask'
import VueMoment from '@plugins/vueMoment'

// Globally register all `_base`-prefixed components
import '@components/_globals'

Vue.use(translator)
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  router,
  ecommerce: {
    enabled: true,
  },
})
Vue.use(VueYoutube)
Vue.config.productionTip = process.env.VUE_APP_BUILD_TARGET === 'production'
Vue.prototype.$GTAG = initialize(process.env.VUE_APP_GOOGLE_GTAG, {
  ative: Vue.config.productionTip,
})

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  vuetify,
  vueMask,
  VueMoment,
  render: (h) => h(App),
}).$mount('#app')
