<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-autocomplete
        v-model="cnaeCodeLocal"
        :search-input.sync="search"
        @keyup="searchCNAE()"
        :items="listCnaes"
        :label="label"
        :filter="setFilter"
        placeholder="Digite o codigo ou a descrição do CNAE"
        :loading="SearchInformation.loading"
        :disabled="disabledWhenInitialize"
        :menu-props="{
          maxHeight: 170,
          maxWidth: 'min-content',
        }"
        :no-data-text="textNoData"
        :rules="rules"
        item-text="fullName"
        item-value="cnaeCode"
        dense
        fab
        single-line
        return-object
        @change="onChangeCode($event)"
      >
        <template v-slot:item="data" class="pa-0 ma-0">
          <v-row no-gutters>
            <v-col class="pb-2 pt-3" cols="12">
              <span class="subtitle-2">
                {{ data.item.fullName }}
              </span>
            </v-col>
            <v-col cols="12">
              <v-divider class="mt-2"></v-divider>
            </v-col>
          </v-row>
        </template>
        <template slot="append" v-if="SearchInformation.loading">
          <v-progress-circular
            size="20"
            width="3"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import SearchInformation from '@src/models/SeachInformation'

export default {
  props: {
    SearchInformation: {
      type: Object,
      require: true,
      default: () => {
        return new SearchInformation('cnae/getAllCnaes')
      },
    },
    code: {
      require: false,
      default: '',
    },
    removeSelected: {
      type: Array,
      require: false,
      default: [],
    },
    label: {
      type: String,
      require: false,
      default: 'Selecione um CNAE',
    },
    rules: {
      require: false,
      Type: Array,
      default() {
        return [(v) => true]
      },
    },
  },
  data() {
    return {
      textNoData: 'Digite o código ou a descrição do CNAE',
      viewItems: true,
      infoCnaeSelected: '',
      search: '',
      disabledWhenInitialize: false,
      cnaes: [],
    }
  },
  computed: {
    cnaeCodeLocal: {
      get() {
        return this.getCodeFromObjectOrString(this.code)
      },
      set(value) {
        if (!!value) {
          this.$emit('input', value)
        } else {
          this.$emit('input', { code: null })
        }
      },
    },
    listCnaes() {
      if (this.viewItems) {
        return this.cnaes.filter(
          (cnae) => !this.removeSelected.includes(cnae.code)
        )
      } else {
        return []
      }
    },
  },
  methods: {
    getCodeFromObjectOrString(cnae) {
      let value = !!cnae && typeof cnae === 'object' ? cnae.code : cnae
      return value
    },
    onChangeCode(value) {
      this.$emit('changeCode', value)
      this.verifyHasInformationInput(value)
    },
    setFilter() {
      return this.cnaes
    },
    verifyHasInformationInput(value) {
      value = this.getCodeFromObjectOrString(value) || ''
      if (value.length > 0) {
        this.viewItems = true
        this.textNoData = 'CNAE não encontrado'
      } else {
        this.viewItems = false
        this.textNoData = 'Digite o código ou a descrição do CNAE'
      }
    },
    async searchCNAE() {
      this.cnaeCodeLocal = { code: null }
      let localSearc = this.getCodeFromObjectOrString(this.search)
      if (localSearc) {
        this.cnaes = await this.SearchInformation.searchDebounceInformation(
          `/?search=${localSearc}`
        )
      }
      this.verifyHasInformationInput(localSearc)
    },
  },
  created() {
    this.search = this.code
    this.searchCNAE()
  },
}
</script>
