import axios from '@plugins/axios'

const baseURL = 'user'

const state = {
  userData: [],
}

const getters = {
  userData: (state) => state.userData,
}

// atribuir um valor
const mutations = {
  updatePhoneUser(state, data) {
    state.userData = data
  },
}

const actions = {
  // commit usa a atribuicao criada no mutations
  async phoneChange({ commit }, phone) {
    await axios
      .put(`/${baseURL}/change-information`, phone)
      .then((res) => {
        commit('updatePhoneUser', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
