import axios from '@plugins/axios'

const baseURL = 'dashboard'

const actions = {
  async get({ commit }) {
    await axios
      .get(`${baseURL}`)
      .then((res) => {
        commit('set', res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  },
}

const mutations = {
  set(state, schedule) {
    state.schedule = schedule
  },
  updateTaxation(state, tax) {
    state.schedule.map((item, index) => {
      if (tax.id === item.id) {
        state.schedule[index].state = tax.state
      }
    })
  },
}

const getters = {
  schedule: (state) => state.schedule,
}

const state = {
  schedule: [],
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
