export function getYears(to, length) {
  const years = []
  const now = new Date().getFullYear()
  const year = to === 'next' ? now + length : now - length
  for (let i = year; i < year + 20; i++) years.push(i)
  return years
}

export const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

export function strMonthToNumber(strMonth) {
  switch (strMonth) {
    case 'Janeiro':
      return '01'
      break
    case 'Fevereiro':
      return '02'
      break
    case 'Março':
      return '03'
      break
    case 'Abril':
      return '04'
      break
    case 'Maio':
      return '05'
      break
    case 'Junho':
      return '06'
      break
    case 'Julho':
      return '07'
      break
    case 'Agosto':
      return '08'
      break
    case 'Setembro':
      return '09'
      break
    case 'Outubro':
      return '10'
      break
    case 'Novembro':
      return '11'
      break
    case 'Dezembro':
      return '12'
      break
    default:
      return null
  }
}
