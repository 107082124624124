import axios from '@plugins/axios'
const baseURL = 'matrimonial-reg'

const state = {
  loading: false,
  matrimonialRegime: [],
}

const getters = {
  matrimonialRegime: (states) => state.matrimonialRegime,
  matrimonialRegimeLoading: (loading) => state.loading,
}

const actions = {
  async getAllMatrimonialRegimes({ commit }) {
    commit('MATRIMONIAL_REGIME_LOADING_START')
    await axios
      .get(`${baseURL}`)
      .then((res) => {
        commit('MATRIMONIAL_REGIME_SET_ALL', res.data)
        commit('MATRIMONIAL_REGIME_LOADING_END')
      })
      .catch((err) => {
        commit('MATRIMONIAL_REGIME_LOADING_END')
      })
  },
}

const mutations = {
  MATRIMONIAL_REGIME_SET_ALL(state, matrimonialRegime) {
    state.matrimonialRegime = matrimonialRegime
  },
  MATRIMONIAL_REGIME_LOADING_START(state) {
    state.loading = true
  },
  MATRIMONIAL_REGIME_LOADING_END(state) {
    state.loading = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
