function currency(value) {
  try {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRA',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return formatter
      .format(value)
      .replace(/^(-)?BRA\s*?/i, '$1 R$')
      .trim()
  } catch (err) {
    console.warn(err.getMessage())
    return 'Ocorreu um problema com o valor'
  }
}

function currencyFromCents(value) {
  return currency(value / 100)
}

function percents(value) {
  let s = value / 100
  s += ''
  return s.replace('.', ',')
}

function toCNPJ(value) {
  if (!value) return ''

  value = value.replace(/\D/g, '')
  value = value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5'
  )

  return value
}

function toRG(value) {
  if (!value) return ''

  value = value.replace(/\W/g, '')
  value = value.replace(/(\d{2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
  return value
}

function toCPF(value) {
  if (!value) return ''

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
  return value
}

function toCEP(value) {
  if (!value) return ''

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{5})(\d{3})$/, '$1-$2')
  return value
}

function toTEL(value) {
  if (!value) return ''
  switch (value.length) {
    case 11:
      value = value.replace(/\D/g, '')
      value = value.replace(/(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
      break
    case 10:
      value = value.replace(/\D/g, '')
      value = value.replace(/(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
      break
  }
  return value
}

function toNIRE(value) {
  if (!value) return ''

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{4})(\d{4})(\d{3})$/, '$1.$2.$3')
  return value
}

function toCodSimpNacional(value) {
  if (!value) return ''

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{3})$/, '$1.$2.$3.$4')
  return value
}

function toInscMunicipal(value) {
  if (!value) return ''

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{3})(\d{3})(\d{2})$/, '$1.$2-$3')
  return value
}

function toInscEstadual(value) {
  if (!value) return ''

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{3})$/, '$1.$2.$3.$4')
  return value
}

function toIPTU(value) {
  if (!value) return ''

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{3})(\d{3})(\d{4})(\d{1})$/, '$1.$2.$3-$4')
  return value
}

function toTituloEleitor(value) {
  if (!value) return ''

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{4})(\d{4})(\d{4})(\d{1})$/, '$1.$2.$3-$4')
  return value
}

function toReciboIRPF(value) {
  if (!value) return ''
  // xx.xx.xx.xx.xx-xx
  value = value.replace(/\D/g, '')
  value = value.replace(
    /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/,
    '$1.$2.$3.$4.$5-$6'
  )
  return value
}
function whiteSpace(value) {
  if (!value) return ''
  value = value.replace(/(?:\r\n|\r|\n)/g, '<br />')
  return value
}
function truncate(text, length, suffix) {
  suffix = suffix || '...'
  if (text.length > length) {
    return `${text.substring(0, length)}${suffix}`
  } else {
    return text
  }
}

const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/
const unmaskText = (text) => {
  return text
    ? String(text).replace(new RegExp(defaultDelimiters, 'g'), '')
    : text
}

function urlDownload(id, uri, jwt) {
  return uri + '/file/download-jwt/' + id + '?jwt=' + jwt
}

function toFixed(value, fixed) {
  return Number(value).toFixed(fixed)
}

function removeAccents(value) {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
function removeCaracterCnae(value) {
  return value.replace(/\D/g, '')
}

function translate(text) {
  text = text || ''
  let ptbr = {
    free: 'Gratuito',
    basic: 'Básico',
    complete: 'Completo',
    months: 'mês',
    weeks: 'semana',
    discount: 'Desconto',
    recurrent: 'Recorrente',
    first_payment: 'Primeiro pagamento',
    regularization: 'Regularização',
    additional: 'Adicional',
  }
  return ptbr[text.toLowerCase()]
}

export default {
  currency: currency,
  currencyFromCents: currencyFromCents,
  toCNPJ: toCNPJ,
  toRG: toRG,
  toCPF: toCPF,
  toCEP: toCEP,
  toTEL: toTEL,
  percents: percents,
  toNIRE: toNIRE,
  toCodSimpNacional: toCodSimpNacional,
  toInscMunicipal: toInscMunicipal,
  toInscEstadual: toInscEstadual,
  toIPTU: toIPTU,
  toTituloEleitor: toTituloEleitor,
  toReciboIRPF: toReciboIRPF,
  whiteSpace: whiteSpace,
  truncate: truncate,
  unmaskText,
  urlDownload: urlDownload,
  toFixed,
  translate,
  removeAccents,
  removeCaracterCnae,
}
