<template>
  <v-img :src="path"></v-img>
</template>
<script>
import registeredSaoPaulo from '@src/assets/images/icons/serves/icone-sao-paulo.png'
export default {
  data() {
    return {
      path: registeredSaoPaulo,
    }
  },
}
</script>
