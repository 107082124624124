export default {
  hello: 'Olá',
  migrating_to_kingkont: 'Migrar minha contabilidade para Simplificador',
  from_mei_to_me: 'Migrar meu regime tributário de MEI para ME',
  email_invalid: 'O email informado não foi encontrado.',
  reset_link_sent: (value) =>
    'Foi enviado no email: ' + value + ', um link para troca de senha.',
  unknow_error_reset_passwowd:
    'Ocorreu um erro ao tentar resetar a sua senha, por favor, tente novamente mais tarde.',
}
