// import api from '../../services/api/user'
import SalesPartner from '@src/services/sales-partner'

const state = {
  agree: true,
  email: null,
  emailAlreadyInUse: false,
  name: null,
  cnpj: null,
  cnpjAlreadyInUse: false,
  phone: null,
  state: null,
  occupation: null,
  idParceiro: null,
  loading: false,
}

const mutations = {
  privayAgree(state, agree) {
    state.agree = agree
  },
  setEmailAlreadyInUse(state) {
    state.emailAlreadyInUse = true
  },
  setEmailAvailable(state) {
    state.emailAlreadyInUse = false
  },
}

const getters = {
  policeAgreed: (state) => state.agree,
}

const actions = {
  async newCreateUser({ state, dispatch }, { dataUser }) {
    dataUser.idParceiro = SalesPartner.getId()
    dataUser.policy = state.agree
    let root = { root: true }
    try {
      await dispatch('auth/creationLatestsUserInformation', dataUser, root)
      const user = await dispatch('auth/fetchUser', null, root)
      return Promise.resolve(user)
    } catch (error) {
      console.log(error)
      return Promise.reject(error.response.data)
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
