import axios from '@plugins/axios'

const baseURL = 'taxPage'

const state = {
  loading: false,
  all: [],
  states: [],
  taxNames: [],
  tax: {
    id: '',
    value: '',
    id_file: '',
    id_company: '',
    created_at: '',
    deleted_at: '',
    detail: '',
    canvas: '',
    state: '',
    valid: '',
  },
  checkTaxReminderAt: null,
}

const getters = {
  taxes: (state) => state.all,
  checkTaxReminderAt: (state) => state.checkTaxReminderAt,
}

const actions = {
  async getAll({ commit }) {
    await axios
      .get(`${baseURL}`)
      .then((res) => {
        commit('setAll', res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async getTax({ commit }, id) {
    await axios
      .get(`${baseURL}/${id}`)
      .then((res) => {
        commit('setTax', res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async updateTax({ state, commit }, { id, file, paidAt }) {
    let data = { id, file, paidAt }
    return await axios
      .put(`${baseURL}/${id}`, data)
      .then((res) => {
        const tax = res.data
        commit('updateTaxation', tax)
        commit('schedule/updateTaxation', tax, { root: true })
        return Promise.resolve(tax)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  },
}

const mutations = {
  setAll(state, taxations) {
    state.all = []
    state.all = taxations
    state.taxNames = taxations.map((tax) => tax.detail)
  },
  setTax(state, resTax) {
    state.tax = resTax
  },
  updateTaxation(state, tax) {
    let listTax = state.all.map((item) => {
      if (tax.id === item.id) {
        item = tax
      }
      return item
    })
    state.all = []
    state.all = listTax
  },
  updateTaxationCategory(state, { category, additionalServiceClientId }) {
    const current = state.all.find((tax) => tax.id === category.id)
    current.additionalServiceClientId = additionalServiceClientId
  },
  setCheckTaxReminderAt(state, newValue) {
    localStorage.setItem('checkTaxReminderAt', Boolean(newValue))
    state.checkTaxReminderAt = newValue
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
