import lazyLoadView from './helper'

import store from '@state/store'

const Dashboard = () => lazyLoadView(import('@src/views/app/Dashboard.vue'))
const Taxation = () =>
  lazyLoadView(import('@src/views/app/Taxation/Taxation.vue'))
const Expense = () => lazyLoadView(import('@src/views/app/Expense/Expense.vue'))
const Company = () => lazyLoadView(import('@src/views/app/Company.vue'))
const SocialContract = () =>
  lazyLoadView(import('@src/views/app/SocialContract.vue'))
const AdditionalServices = () =>
  lazyLoadView(
    import('@src/views/app/additionalServices/AdditionalServices.vue')
  )
const RecordsList = () =>
  lazyLoadView(import('@src/views/app/additionalServices/RecordsList.vue'))
const ShoppingList = () =>
  lazyLoadView(import('@src/views/app/additionalServices/ShoppingList.vue'))
const NewService = () =>
  lazyLoadView(import('@src/views/app/additionalServices/newService.vue'))
const DetailService = () =>
  lazyLoadView(import('@src/views/app/additionalServices/detailService.vue'))
const UsageStatement = () =>
  lazyLoadView(import('@src/views/app/UsageStatement/UsageStatement.vue'))
const Partners = () => lazyLoadView(import('@src/views/app/Partners.vue'))
const Partner = () => lazyLoadView(import('@src/views/app/Partner.vue'))
const ContractServices = () =>
  lazyLoadView(import('@src/views/app/ContractServices.vue'))
const PaymentForm = () => lazyLoadView(import('@src/views/app/PaymentForm.vue'))
const PlanData = () => lazyLoadView(import('@src/views/app/PlanData.vue'))
const ResetPassword = () =>
  lazyLoadView(import('@src/views/app/ChangePasswordTemplate.vue'))
const AccountCancel = () =>
  lazyLoadView(import('@src/views/app/AccountCancel.vue'))
const TaxDocument = () => lazyLoadView(import('@src/views/app/TaxDocument.vue'))
const HelpCenter = () => lazyLoadView(import('@src/views/app/HelpCenter.vue'))
const AnnualDeclaration = () =>
  lazyLoadView(import('@src/views/app/annualDeclaration/AnnualDeclaration.vue'))
const Vouchers = () => lazyLoadView(import('@src/views/app/Vouchers.vue'))
const Scheduling = () =>
  lazyLoadView(import('@src/views/app/scheduling/scheduling.vue'))
// const isAuthenticated = store.getters['shortLink/shorLinkData']

const appRouters = [
  {
    path: '',
    component: () => lazyLoadView(import('@src/views/app/LayoutApp.vue')),
    meta: {
      authRequired: true,
      allowedSteps: [2, 3, 4, 5],
    },
    children: [
      {
        name: 'home',
        path: '',
        component: Dashboard,
      },
      {
        path: '/impostos',
        name: 'taxation',
        component: Taxation,
      },
      {
        path: '/despesas',
        name: 'Expense',
        component: Expense,
      },
      {
        path: '/empresa',
        name: 'Company',
        component: Company,
      },
      {
        path: '/contrato-social',
        name: 'SocialContract',
        component: SocialContract,
      },
      {
        path: '/servicos-adicionais',
        component: AdditionalServices,

        children: [
          {
            path: '',
            name: 'AdditionalServices',
            component: RecordsList,
            meta: {
              authRequired: true,
            },
          },
          {
            path: 'busca',
            name: 'ShoppingList',
            component: ShoppingList,
            meta: {
              authRequired: true,
            },
          },
          {
            path: 'new/:idService',
            name: 'newService',
            component: NewService,
            meta: {
              authRequired: true,
            },
          },
          {
            path: 'servico/:idOrderService',
            name: 'DetailService',
            component: DetailService,
            meta: {
              authRequired: true,
            },
          },
        ],
      },
      {
        path: '/extrato',
        name: 'UsageStatement',
        component: UsageStatement,
      },
      {
        path: '/socios',
        name: 'Partners',
        component: Partners,
      },
      {
        props: true,
        path: '/socio/:id',
        name: 'Partner',
        component: Partner,
      },
      {
        path: '/contrato-servicos',
        name: 'ContractServices',
        component: ContractServices,
      },
      {
        path: '/formas-de-pagamento',
        name: 'PaymentForm',
        component: PaymentForm,
      },
      {
        path: '/dados-do-plano',
        name: 'PlanData',
        component: PlanData,
      },
      {
        path: '/alterar-senha',
        name: 'ResetPassword',
        component: ResetPassword,
      },
      {
        path: '/documentos-contabeis',
        name: 'TaxDocument',
        component: TaxDocument,
      },
      {
        path: '/cancelar-conta',
        name: 'AccountCancel',
        component: AccountCancel,
      },
      {
        path: '/help-center',
        name: 'HelpCenter',
        component: HelpCenter,
      },
      {
        path: '/annual-declaration',
        name: 'AnnualDeclaration',
        component: AnnualDeclaration,
      },
      {
        path: '/vouchers',
        name: 'vouchers',
        component: Vouchers,
      },
      {
        name: 'comparativePlan',
        path: '/comparativo-planos',
        component: () =>
          lazyLoadView(import('@src/views/app/ComparativePlan.vue')),
      },
      {
        name: 'certificade',
        path: '/certificado',
        component: () => lazyLoadView(import('@/views/app/Certificates.vue')),
      },
    ],
  },
  {
    name: 'diaries',
    path: '/agendamento-:idType',
    component: Scheduling,
  },
  {
    name: 'short-link',
    path: '/short:token',
    meta: {
      async beforeResolve(routerTo, routerFrom, routerNext) {
        try {
          const token = routerTo.params.token
          const res = await store.dispatch('shortLink/getShortLink', token)
          window.location.replace(res.url)
        } catch (error) {
          console.log('nao encontrada', error)
          routerNext({ name: 'page-not-found' })
        }
      },
    },
  },
]
export default appRouters
