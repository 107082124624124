import axios from '@plugins/axios'

const state = {
  mei: [
    {
      title: 'Processos iniciais',
      activities: [
        {
          name: 'Abertura do CNPJ',
          defaultRule: {
            free: {
              type: 'unlimited',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Liberação para emissão de Notas Fiscais',
          defaultRule: {
            free: {
              type: 'normal',
              value: 10000,
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Migração',
          defaultRule: {
            free: {
              type: 'unlimited',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
      ],
    },
    {
      title: 'Rotina',
      activities: [
        {
          name: 'Disponibilização de guias DAS-MEI para pagamento de impostos',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name:
            'Transmissão do Imposto de Renda da Pessoa Jurídica - Declaração Anual Simples Nacional do MEI (DASNMEI).',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Relatórios contábeis',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Informe de Rendimentos para pessoa física',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Escrituração Contábil',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Escrituração Fiscal',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name:
            'Atendimento das demais exigências previstas na legislação brasileira',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
      ],
    },
    {
      title: 'Avisos e Conferências',
      activities: [
        {
          name: 'Conferência anual do DAS-MEI em aberto',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Conferência do pagamento dos Impostos DAS-MEI',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'isBlocked',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Aviso de SMS de Guias disponíveis para pagamento',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'isBlocked',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Lembretes por SMS do vencimento das Guias',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'isBlocked',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Atendimento via chat, telefone ou e-mail',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Horas de consultoria ANUAL',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 15000,
            },
            complete: {
              type: 'fixed',
              value: 15000,
              limit: 3,
              limitType: 'year',
            },
          },
        },
      ],
    },
    {
      title: 'Documentos sob demandas',
      activities: [
        {
          name: 'Reemissão de guias de impostos e taxas',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 1000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Parcelamento de impostos do MEI',
          defaultRule: {
            free: {
              type: 'normal',
              value: 15000,
            },
            basic: {
              type: 'normal',
              value: 5000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'ReDARF on-line',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 5000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Regularização de ano de MEI',
          defaultRule: {
            free: {
              type: 'normal',
              value: 25000,
            },
            basic: {
              type: 'normal',
              value: 25000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Reemissão de documentos vencidos',
          defaultRule: {
            free: {
              type: 'normal',
              value: 10000,
            },
            basic: {
              type: 'normal',
              value: 10000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'CNDs regulares',
          defaultRule: {
            free: {
              type: 'normal',
              value: 5000,
            },
            basic: {
              type: 'normal',
              value: 5000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Emissão de DECORE',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 15000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Relatórios de faturamento',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 5000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Retificação de Declaração de IRPF',
          defaultRule: {
            free: {
              type: 'normal',
              value: 10000,
            },
            basic: {
              type: 'normal',
              value: 10000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name:
            'Contabilidade de períodos anteriores Simples Nacional sem movimento',
          defaultRule: {
            free: {
              type: 'normal',
              value: 4000,
            },
            basic: {
              type: 'normal',
              value: 4000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name:
            'CPOM/CEPOM - Cadastro de Prestadores de Serviços de Outros Municípios',
          defaultRule: {
            free: {
              type: 'normal',
              value: 30000,
            },
            basic: {
              type: 'normal',
              value: 30000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Cadastro no CTR-e (revisão se necessário)',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 5000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
      ],
    },
  ],
  me: [
    {
      title: 'Processos iniciais',
      activities: [
        {
          name: 'Abertura do CNPJ',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Liberação para emissão de Notas Fiscais',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Migração',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
      ],
    },
    {
      title: 'Rotina',
      activities: [
        {
          name: 'Todas as obrigações contábeis',
          panelActive: false,
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
          details: [
            'Cálculo e emissão de guia para recolhimento e INSS e IRRF.',
            'Cálculo e emissão de guias DAS para pagamento de impostos do Simples Nacional',
            'Transmissão do Imposto de Renda da Pessoa Jurídica - IRPJ (DEFIS).',
            'Relatórios contábeis',
            'Informe de Rendimentos para pessoa física',
            'Escrituração Contábil',
            'Escrituração Fiscal',
            'Atendimento das demais exigências previstas na legislação brasileira.',
          ],
        },
      ],
    },
    {
      title: 'Alterações na empresa',
      activities: [
        {
          name: 'Atualização de dados básicos',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 20000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Alteração contratual padrão',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 30000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Alteração contratual completa',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 50000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
      ],
    },
    {
      title: 'Avisos e Conferências',
      activities: [
        {
          name: 'Conferência anual do DAS em aberto',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 2500,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Conferência do pagamento dos Impostos DAS e GPS',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 7500,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Conferência anual do pagamento da TFE',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'isBlocked',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Aviso de SMS de Guias disponíveis para pagamento',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'isBlocked',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Lembretes por SMS do vencimento das Guias',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'isBlocked',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Atendimento via chat, telefone ou e-mail',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'unlimited',
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Cadastro no CTR-e (revisão se necessário)',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 5000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
      ],
    },
    {
      title: 'Documentos sob demandas',
      activities: [
        {
          name: 'Reemissão de guias de impostos e taxas',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 1000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Parcelamento dos impostos do Simples Nacional',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 15000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'ReDARF on-line',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 5000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'RAIS negativa de anos anteriores',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 15000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Emissão de DECORE',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 15000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Relatórios de faturamento',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 5000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
        {
          name: 'Retificação de Declaração de IRPF',
          defaultRule: {
            free: {
              type: 'isBlocked',
            },
            basic: {
              type: 'normal',
              value: 10000,
            },
            complete: {
              type: 'unlimited',
            },
          },
        },
      ],
    },
  ],
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
