import axios from '@plugins/axios'

const baseURL = 'payments-page'

export const TYPE_CREDITCARD = 1
export const TYPE_BOLETO = 2

const state = {}

const getters = {}

const mutations = {}

const actions = {
  async cahngePaymentMethod(
    { commit },
    { paymentType, tokenIugu, creditCardLastNumbers }
  ) {
    paymentType, tokenIugu, creditCardLastNumbers
    try {
      await axios.put(`/${baseURL}`, {
        payment_type: paymentType,
        credit_card_token_iugu: tokenIugu,
        credit_card_last_numbers: creditCardLastNumbers,
      })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject()
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
