import axios from '@plugins/axios'

const baseURL = 'plan-change-notification'

const state = {
  planToChange: {},
}

const getters = {
  planToChange: (state) => state.planToChange,
}

const mutations = {
  setPlanToChange(state, data) {
    state.planToChange = data
  },
  deletePlanToChange(state) {
    state.planToChange = {}
  },
}

const actions = {
  async savePlanDesiredChange({ commit }, idDesiredPlan) {
    await axios
      .post(`/${baseURL}`, idDesiredPlan)
      .then((res) => {
        commit('setPlanToChange', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getPlanDesiredChange({ commit }) {
    await axios
      .get(`/${baseURL}`)
      .then((res) => {
        commit('setPlanToChange', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async deletePlanDesiredChange({ commit }, idNotification) {
    await axios
      .delete(`/${baseURL}/${idNotification}`)
      .then((res) => {
        commit('deletePlanToChange', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
