import axios from '@plugins/axios'

export const STATE_SP_ID = 26
const baseURL = 'state'

const state = {
  laoding: false,
  states: [],
}

const getters = {
  states: (state) => state.states,
  loading: (loading) => state.loading,
  stateSelected: () => {
    let stateSelected = {
      uf: '',
    }
    return stateSelected
  },
}

const actions = {
  async getAllStates({ commit, state }) {
    if (state.loading) return false
    commit('STATE_LOADING_START')
    await axios
      .get(`${baseURL}`)
      .then((res) => {
        commit('STATE_SET_ALL', res.data)
        commit('STATE_LOADING_END')
      })
      .catch((err) => {
        commit('STATE_LOADING_END')
      })
  },
}

const mutations = {
  STATE_SET_ALL(state, states) {
    state.states = states
  },
  STATE_LOADING_START(state) {
    state.loading = true
  },
  STATE_LOADING_END(state) {
    state.loading = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
