import Login from './login'
import Errors from './errors'
import newRegistering from './newRegistering'
import App from './app'

export default [
  Login,
  newRegistering,
  Errors,
  ...App,
  {
    path: '*',
    redirect: '/error/page-not-found',
  },
]
