export default {
  id: 2,
  name: '',
  email: '',
  partnershipId: null,
  couponCode: '',
  isDesktop: null,
  sourceUrl: null,
  typeOfRegistration: {
    value: null,
    name: '',
  },
  hasPartner: null,
  city: '',
  activityNature: '',
  activityDescription: null,
  hasEmployee: null,
  salesForecast: {
    value: null,
    name: '',
  },
  juridicType: '',
  mainCnae: {
    code: '',
    description: '',
    active: null,
    anexo: '',
    fatorR: '',
    simples: null,
    simplesNacional: 1,
    inscricaoEstadual: null,
    cpp: null,
    fpa: null,
    rat: '',
    tfeCode: '',
    tfeValueCent: null,
    nationalSimpleStartingPercentage: '',
    activityDescription: '',
    inscricaoEstadualObservation: '',
    removed: null,
    hasMei: null,
    meiDescription: null,
    cityServiceCode: null,
    citiesServiceCodes: null,
    codeWithMask: '',
  },
  secondaryCnae: [],
  lastQuest: '',
  userId: null,
  isQualified: {
    isAllQualified: null,
    checkCity: null,
    checkActivityNature: null,
    checkHasEmployee: null,
    checkJuridicType: null,
    checkCnae: null,
    cnaeSubCaen: null,
  },
}
