import axios from '@plugins/axios'
import formData from '@src/models/formData'

const baseURL = 'form-registering'

const OPENING = 1
const MIGRATION = 2
const TRANSFORMATION = 3
const OPENING_MEI = 4
const MIGRATION_MEI = 5
const AUTONOMOUS = 6

const state = {
  formUser: Object.assign(formData),
  emailAlreadyInUse: false,
}

const getters = {
  formUser: (state) => state.formUser,
  emailAlreadyInUse: (state) => state.emailAlreadyInUse,
  isOpening: (state) => state.formUser?.typeOfRegistration.value === OPENING,
  checkCity: (state) => state.formUser?.isQualified.city,
  checkActivityNature: (state) => state.formUser?.isQualified.activityNature,
  checkHasEmployee: (state) => state.formUser?.isQualified.employee,
  checkJuridicType: (state) => state.formUser?.isQualified.juridicType,
  checkCnae: (state) => state.formUser?.isQualified.cnae,
  subCaen: (state) => state.formUser?.isQualified.subCaen,
  isAutonomous: (state) =>
    state.formUser?.typeOfRegistration.value === AUTONOMOUS,
}

const actions = {
  async getFormUser({ commit }) {
    return await axios
      .get(`${baseURL}`)
      .then((res) => {
        const formUser = res.data
        commit('setFormUser', formUser)
        return Promise.resolve(formUser)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  },
  async resetUserForm({ state, commit }) {
    await axios
      .post(`form-registering-reset`)
      .then((res) => {
        commit('setFormUser', res.data.formUser)
        return Promise.resolve(true)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  },
  async updatedFormUser({ commit }, data) {
    try {
      const formUser = await axios
        .put(`${baseURL}`, data)
        .then((res) => res.data)
      commit('putFormUser', { res: formUser, updated: data })
      return Promise.resolve(formUser)
    } catch (error) {
      console.log('1', error)
      return Promise.reject(error)
    }
  },
  async goToNextStep({ commit, dispatch }) {
    try {
      await axios.put(`${baseURL}/next-status`)
      await dispatch('getFormUser')
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },
}

const mutations = {
  setFormUser(state, formUser) {
    state.formUser = formUser
  },
  setEmailAlreadyInUse(state) {
    state.emailAlreadyInUse = true
  },
  setEmailAvailable(state) {
    state.emailAlreadyInUse = false
  },
  putFormUser(state, { res, updated }) {
    Object.keys(updated).map((key) => {
      state.formUser[key] = res[key]
    })
    state.formUser.isQualified = res.isQualified
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
