import axios from '@plugins/axios'

const baseURL = 'provider'

const state = {
  listProvidersActive: [],
  listProvidersAll: [],
  listProviders: [],
}

const getters = {
  listProvidersAll: (state) => state.listProviders,
  listProvidersActive: (state) =>
    state.listProviders.filter((item) => !!item.active),
}

// atribuir um valor
const mutations = {
  setListProviders(state, data) {
    state.listProviders = data
  },
  pushProvider(state, item) {
    state.listProviders.push(item)
  },
  updateProvider(state, data) {
    let itemProvider = state.listProviders.map((item) => {
      if (data.id === item.id) {
        item = data
      }
      return item
    })
    state.listProviders = []
    state.listProviders = itemProvider
  },
  disableRegister(state, data) {
    state.listProviders = state.listProviders.map((item) => {
      if (item.id == data.id) {
        item = data
      }
      return item
    })
  },
}

const actions = {
  // commit usa a atribuicao criada no mutations
  async getListProviders({ commit }) {
    await axios
      .get(`/${baseURL}`)
      .then((res) => {
        commit('setListProviders', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async createProvider({ commit }, data) {
    await axios
      .post(`/${baseURL}`, data)
      .then((res) => {
        commit('pushProvider', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async updateProvider({ commit }, data) {
    await axios
      .put(`/${baseURL}/${data.id}`, data)
      .then((res) => {
        commit('updateProvider', res.data)
        commit('expenses/updateProvider', res.data, { root: true })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async disableProvider({ commit }, data) {
    await axios
      .put(`/${baseURL}-disable/${data.id}`, data)
      .then((res) => {
        commit('disableRegister', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
