import axios from '@plugins/axios'

const baseURL = 'plan'

const actions = {
  async getPlans({ state, commit }) {
    state.loading = true
    await axios
      .get(`${baseURL}-all`)
      .then((res) => {
        commit('setPlans', res.data)
        commit('setPlanMEI', res.data)
        commit('setPlanME', res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  },
}

const mutations = {
  setPlans(state, data) {
    state.plans = data
  },
  setPlanMEI(state, data) {
    state.planMEI = data.find((item) => item.type === 'MEI')
  },
  setPlanME(state, data) {
    state.planME = data.find((item) => item.type === 'ME')
  },
  setComparativeList(state, data) {
    state.comparativeList = data
  },
}

const getters = {
  plans: (state) => state.plans,
  planMEI: (state) => state.planMEI,
  planME: (state) => state.planME,
  fullComparativeList(state) {
    return [
      {
        id: null,
        name: 'Todas as obrigações contábeis',
        plans: {
          me: {
            basic: {
              correctValue: null,
              limit: null,
              limitDemarcation: 'unlimited',
              limitType: null,
              isBlocked: null,
            },
            complete: {
              correctValue: null,
              limit: null,
              limitDemarcation: 'unlimited',
              limitType: null,
              isBlocked: null,
            },
          },
          mei: {},
        },
        details: [
          'Cálculo e emissão de guia para recolhimento e INSS e IRRF.',
          'Cálculo e emissão de guias DAS para pagamento de impostos do Simples Nacional',
          'Transmissão do Imposto de Renda da Pessoa Jurídica - IRPJ (DEFIS).',
          'Relatórios contábeis',
          'Informe de Rendimentos para pessoa física',
          'Escrituração Contábil',
          'Escrituração Fiscal',
          'Atendimento das demais exigências previstas na legislação brasileira.',
        ],
      },
      ...state.comparativeList,
    ]
  },
}

const state = {
  loading: false,
  plans: [],
  planME: {},
  planMEI: {},
  comparativeList: [],
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
