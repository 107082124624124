<template>
  <span class="k-header-date">
    <div class="year">{{ this.date | moment('YYYY') }} </div>
    <div class="mounth">
      {{ this.date | moment('ddd, DD MMM') }}
    </div>
  </span>
</template>
<script>
export default {
  name: 'HeaderDate',
  data() {
    return {}
  },
  props: {
    date: {
      require: false,
      type: Date,
      default: () => new Date(),
    },
  },
}
</script>

<style lang="sass" scoped>
.k-header-date
  right: 0%
  text-align: right
  line-height: normal
  .year
    font-size: 16px

  .mounth
    font-size: 24px
</style>
