import store from '@state/store'

class SearchInformation {
  storeMethod = ''
  queryString = ''
  loading = false

  constructor(storeMethod) {
    this.storeMethod = storeMethod
  }

  async getInformationApi() {
    this.loading = true
    const resp = await store.dispatch(this.storeMethod, this.queryString)
    this.loading = false
    return resp
  }

  searchDebounceInformation(queryString) {
    return new Promise(async (resolve, reject) => {
      this.queryString = queryString ? queryString : ''
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        resolve(await this.getInformationApi())
      }, 1000)
    })
  }
}

export default SearchInformation
