<template>
  <div>
    <v-card-title primary-title :class="[color, 'display-1', 'white--text']">
      <v-row
        v-if="$vuetify.breakpoint.smAndUp"
        justify="space-between"
        class="mx-2"
      >
        <div>
          <span class="font-weight-medium">{{ title }}</span>
        </div>
        <div>
          <template v-if="showDate">
            <k-header-date></k-header-date>
          </template>
        </div>
      </v-row>

      <v-row v-else>
        <v-col cols="12" class="py-0">
          <p
            class="font-weight-medium display-1 white--text"
            style="word-break: break-word"
            >{{ title }}</p
          >
          <template v-if="showDate">
            <k-header-date></k-header-date>
          </template>
        </v-col>
      </v-row>

      <template v-if="filterDate">
        <v-col cols="12" style="height: 2rem;">
          <v-row class="fill-height" align="center" justify="center">
            <v-col class="text-right" cols="3">
              <v-btn icon @click="previousMonth()">
                <v-icon medium>keyboard_arrow_left</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" class="text-center subtitle-1">
              <span>{{ filter }}</span>
            </v-col>
            <v-col class="text-left" cols="3">
              <v-btn @click="nextMonth()" icon>
                <v-icon medium>keyboard_arrow_right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-card-title>
    <v-row class="mt-2 mx-2 hidden-sm-and-down" justify="space-between">
      <div class="px-3 text-center d-flex align-center align-self-center">
        <v-icon>textsms</v-icon>
      </div>
      <v-col class="subtitle-1">
        <slot name="description">
          {{ description }}
        </slot>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import kHeaderDate from '@src/components/widget/HeaderDate.vue'
import { months, strMonthToNumber } from '@src/services/date'

export default {
  name: 'HeaderInformation',
  components: {
    kHeaderDate,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    filterDate: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'grey lighten-1',
    },
    showDate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      months,
      filterMonth: months[new Date().getMonth()],
      filterYear: new Date().getFullYear(),
      monthToNumber: Number(
        strMonthToNumber(months[new Date().getMonth()]) - 1
      ),
      next: '',
      previous: '',
    }
  },
  computed: {
    filter() {
      return `${this.filterMonth} ${this.filterYear}`
    },
  },
  methods: {
    nextMonth() {
      let next = this.monthToNumber

      next = next + 1
      if (next < 12) {
        this.filterMonth = months[next]
        this.monthToNumber = next
        this.monthToNumber = next
        this.emitFilterChange()
      } else {
        next = 0
        this.filterMonth = months[next]
        this.filterYear++
        this.monthToNumber = next
        this.emitFilterChange()
      }
    },
    previousMonth() {
      let previous = this.monthToNumber
      previous = previous - 1
      if (previous >= 0) {
        this.filterMonth = months[previous]
        this.monthToNumber = previous
        this.emitFilterChange()
      } else {
        previous = 11
        this.filterMonth = months[previous]
        this.filterYear--
        this.monthToNumber = previous
        this.emitFilterChange()
      }
    },
    emitFilterChange() {
      this.$emit('changeFilter', {
        date: this.$moment(
          this.filterYear + '-' + strMonthToNumber(this.filterMonth) + '-01'
        ),
      })
    },
  },
}
</script>

<style scoped></style>
