import axios from '@plugins/axios'

const baseURL = 'voucher'

const state = {
  voucher: {},
  report: {},
}

const getters = {
  voucher: (state) => state.voucher,
  report: (state) => state.report,
}

const mutations = {
  setVoucher(state, data) {
    state.voucher = data
  },
  setReport(state, data) {
    state.report = data
  },
}

const actions = {
  async getVoucher({ commit }) {
    await axios
      .get(`/${baseURL}`)
      .then((res) => {
        commit('setVoucher', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getReport({ commit }) {
    await axios
      .get(`/${baseURL}/report`)
      .then((res) => {
        commit('setReport', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
