import axios from '@plugins/axios'

const state = {
  loading: false,
  cnaes: [],
  cnae: {},
}

const getters = {
  cnaes: (states) => state.cnaes,
  cnae: (states) => state.cnae,
  cnaesLoading: (loading) => state.loading,
}

const actions = {
  async getAllCnaes({ commit }, query = '') {
    commit('CNAES_LOADING_START')
    return await axios
      .get(`cnae${query}`)
      .then((res) => {
        commit('CNAES_SET_ALL', res.data)
        commit('CNAES_LOADING_END')
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        commit('CNAES_LOADING_END')
        return Promise.reject()
      })
  },
  async getCnae({ commit }, cnaeCode) {
    commit('CNAES_LOADING_START')
    await axios
      .get(`cnae/${cnaeCode}`)
      .then((res) => {
        commit('CNAE_SET', res.data)
        commit('CNAES_LOADING_END')
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        commit('CNAES_LOADING_END')
        return Promise.reject()
      })
  },
}

const mutations = {
  CNAES_SET_ALL(state, cnaes) {
    state.cnaes = cnaes
  },
  CNAE_SET(state, cnae) {
    state.cnae = cnae
  },
  CNAES_LOADING_START(state) {
    state.loading = true
  },
  CNAES_LOADING_END(state) {
    state.loading = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
