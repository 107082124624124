const mutations = {
  setSuccessRegister(state) {
    state.notification = false
    setNewMessage(state, 'Cadastro realizado com sucesso!')
  },
  setSuccessEdition(state) {
    state.notification = false
    setNewMessage(state, 'Edição realizada com sucesso!')
  },
  setSuccessExclusion(state) {
    state.notification = false
    setNewMessage(state, 'Excluído com sucesso!')
  },
}

const getters = {
  notificationText: (state) => state.notificationText,
  notification: (state) => state.notification,
  color: (state) => state.color,
  top: (state) => state.top,
}

const actions = {}

const state = {
  notificationText: '',
  notification: false,
  color: 'success',
  top: true,
}

function setNewMessage(state, text) {
  setTimeout(() => {
    state.notificationText = text
    state.notification = true
    state.color = 'success'
  }, 250)
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
