<template>
  <v-row no-gutters justify="start">
    <v-col class="d-flex flex-row justify-space-between">
      <v-col class="pl-0">
        <div class="elevation-4 col-cnae">
          <v-col class="pb-0">
            <span class="subtitle-1 text--secondary font-weight-bold ">
              {{ title }}
            </span>
          </v-col>
          <v-col v-if="isEditable">
            <base-input-search-cnae
              :code="content.code"
              v-model="localCnae"
              :rules="rules"
              :label="label"
              :isReturnObjectForItemSelected="true"
              :removeSelected="removeSelected"
            ></base-input-search-cnae>
          </v-col>
          <v-col v-else>
            <strong>{{ localCnae.codeWithMask }}</strong>
            <br />
            <span>{{ localCnae.description }}</span>
          </v-col>
          <v-col class="d-flex flex-row justify-start pt-0">
            <v-btn
              v-if="!isEditable"
              x-small
              class="custom-btn-green rounded-0 mr-2"
              @click="editValue"
              :loading="loadingBtnChange"
            >
              Alterar
            </v-btn>
            <v-btn
              x-small
              class="custom-btn-green rounded-0 mr-2"
              dark
              outlined
              v-if="isEditable && !!content.code"
              @click="cancel"
            >
              Cancelar
            </v-btn>
            <v-btn
              v-if="canDelete"
              x-small
              color="#ef5350"
              class="rounded-0"
              dark
              :loading="loadingBtnDelete"
              @click="$emit('delete', true)"
            >
              Excluir
            </v-btn>
          </v-col>
        </div>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: {
      require: true,
      default: () => {
        return { code: '' }
      },
    },
    rules: {
      type: Array,
      default: () => {
        return []
      },
    },
    title: {
      type: String,
      default: 'title',
    },
    label: {
      type: String,
      default: 'Selecione o CNAE secundário',
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    selectedCneas: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      content: this.value || { code: '' },
      loading: false,
      loadingBtnChange: false,
      loadingBtnDelete: false,
      isEdit: true,
    }
  },
  computed: {
    localCnae: {
      get() {
        return this.value || { code: null }
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
    isEditable() {
      return !this.localCnae.code
    },
    removeSelected() {
      return this.selectedCneas.reduce((accumulator, item) => {
        if (item) accumulator.push(item.code)
        return accumulator
      }, [])
    },
  },
  methods: {
    save() {},
    editValue() {
      this.content = this.localCnae
      this.localCnae = null
    },
    cancel() {
      this.localCnae = this.content
    },
  },
}
</script>
<style lang="scss" module>
@import '@design';
</style>
