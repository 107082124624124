import axios from '@plugins/axios'

const baseURL = 'taxonomy'

const state = {}

const getters = {}

const actions = {
  async savePartnerRG({ dispatch, commit }, { idPartner, file }) {
    try {
      const resp = await axios.post(`${baseURL}/RG`, { file: file })
      await dispatch(
        'partner/savePartnerField',
        {
          id: idPartner,
          idRg: resp.data.id,
        },
        { root: true }
      )
      return Promise.resolve(resp.data)
    } catch (error) {
      console.log(error)

      return Promise.reject(error)
    }
  },
  async saveCompanyContratoSocialConstituicao({ dispatch, commit }, data) {
    commit('company/setLoadingContratoSocial', true, { root: true })
    return await axios
      .post(`${baseURL}/Contrato Social - Constituição`, { file: data })
      .then((resp) => {
        dispatch(
          'company/updateCompany',
          {
            idContratoSocial: resp.data.id,
            hasSocialContract: true,
          },
          { root: true }
        )
        commit('company/setLoadingContratoSocial', false, {
          root: true,
        })
        return Promise.resolve(resp.data)
      })
      .catch((err) => {
        console.log(err)
        commit('company/setLoadingContratoSocial', false, {
          root: true,
        })
        return Promise.reject(err)
      })
  },
  async saveCompanyFileIptu({ dispatch, commit }, data) {
    commit('company/setLoadingIptu', true, { root: true })
    return await axios
      .post(`${baseURL}/Capa do IPTU`, { file: data })
      .then((resp) => {
        dispatch(
          'company/updateCompany',
          { idFileIptu: resp.data.id },
          { root: true }
        )
        commit('company/setLoadingIptu', false, { root: true })
        return Promise.resolve(resp.data)
      })
      .catch((err) => {
        console.log(err)
        commit('company/setLoadingIptu', false, { root: true })
        return Promise.reject(err)
      })
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
