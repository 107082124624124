import axios from '@plugins/axios'

const baseURL = 'additional-service'

const state = {
  services: [],
  error: false,
  additionalService: {},
  orderService: {},
  listServices: [],
}

const getters = {
  listServices: (state) => state.listServices,
  additionalService: (state) => state.additionalService || {},
  orderService: (state) => state.orderService,
}

const actions = {
  async getListOrderService({ commit, state }) {
    await axios
      .get(`${baseURL}`)
      .then((res) => commit('setServices', res.data))
      .catch((res) => res)
  },
  async getListServices({ commit, state }) {
    await axios
      .get(`${baseURL}-list`)
      .then((res) => commit('setListServices', res.data))
      .catch((res) => res)
  },
  async createOrderService({ commit }, data) {
    return await new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}`, data)
        .then((res) => {
          return resolve(res.data)
        })
        .catch((error) => {
          return reject(error.response.data || null)
        })
    })
  },
  async aproveOrderService({ commit }, data) {
    return await new Promise((resolve, reject) => {
      axios
        .put(`${baseURL}/${data.id}`, data)
        .then((res) => {
          commit('setOrderService', res.data)
          return resolve(res.data)
        })
        .catch((error) => {
          return reject(error.response.data || null)
        })
    })
  },
  async getAdditionalService({ commit }, id) {
    await axios
      .get(`${baseURL}-show/${id}`)
      .then((res) => {
        commit('setAdditionalService', res.data)
      })
      .catch((res) => {
        console.log(res)
      })
  },
  async getOrderService({ commit }, id) {
    await axios
      .get(`${baseURL}/${id}`)
      .then((res) => {
        commit('setOrderService', res.data)
      })
      .catch((res) => {
        console.log(res)
      })
  },
}

const mutations = {
  setOrderService(state, newValue) {
    state.orderService = newValue
  },
  setAdditionalService(state, data) {
    state.additionalService = data
  },
  setServices(state, newValue) {
    state.services = newValue
  },
  setListServices(state, newValue) {
    state.listServices = newValue
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
