export default {
  id: null,
  name: null,
  email: null,
  cpfCnpj: null,
  step: { value: null, name: null },
  coupons: [],
  paymentMethod: { value: null, name: null },
  paymentGatewayId: null,
  address: {
    cep: null,
    addressNumber: null,
    addressComplement: null,
    address: null,
    district: null,
    state: null,
    city: null,
    isInvalid: false,
  },
  currentSubscription: {
    id: null,
    periodEnd: null,
    periodStart: null,
    expireAt: null,
    active: true,
    paymentGatewayId: null,
    planItem: {
      id: null,
      classification: null,
      expireAt: null,
      active: true,
      comment: null,
      name: null,
      quantity: 0,
      price: null,
      coupon: null,
      paymentGatewayId: null,
      isPlan: true,
    },
    plan: {
      id: null,
      name: null,
      currentValue: 0,
      type: null,
      active: true,
      additional: [
        {
          id: null,
          value: 0,
          limit: null,
          limitDemarcation: null,
          limitType: null,
          isBlocked: true,
          name: null,
          description: null,
          classification: null,
          active: true,
          requestCount: 0,
          planId: null,
          activityId: null,
          isNew: false,
          currentValuePlan: null,
          currentPriceIdPlan: null,
          deadline: null,
        },
      ],
      currentPriceId: null,
      classification: null,
    },
  },
  suspendedAt: null,
  userId: null,
  itemsForNextCharges: [
    {
      id: null,
      classification: null,
      expireAt: null,
      active: true,
      comment: null,
      name: null,
      quantity: 0,
      price: null,
      coupon: null,
      paymentGatewayId: null,
    },
  ],
  firstInvoiceLink: null,
}
