import axios from '@plugins/axios'

const baseURL = 'short-link'

const state = {
  shorLinkData: [],
}

const getters = {
  shorLinkData: (state) => state.shorLinkData,
}

// atribuir um valor
const mutations = {
  setShortLink(state, url) {
    state.shorLinkData = url
  },
}

const actions = {
  // commit usa a atribuicao criada no mutations
  async getShortLink({ commit }, code) {
    return await axios
      .get(`/${baseURL}/${code}`)
      .then((res) => {
        commit('setShortLink', res.data)
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        console.log(error)
        return Promise.reject(error)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
