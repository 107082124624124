import axios from '@plugins/axios'

const baseURL = 'diaries'

const state = {
  scheduling: {},
  listAvaliable: [],
  valueAlert: false,
  type: {},
}

const getters = {
  scheduling: (state) => state.scheduling,
  listAvaliable: (state) => state.listAvaliable,
  valueAlert: (state) => state.valueAlert,
  type: (state) => state.type,
}

// atribuir um valor
const mutations = {
  setListAvaliable(state, data) {
    state.listAvaliable = data
  },
  pushScheduling(state, data) {
    state.scheduling = data
  },
  showAlert(state, value) {
    state.valueAlert = value
  },
  setType(state, value) {
    state.type = value
  },
}

const actions = {
  // commit usa a atribuicao criada no mutations
  async getListAvaliable({ commit }, { typeId, startDay, endDay }) {
    await axios
      .post(`/${baseURL}-list`, { typeId, startDay, endDay })
      .then((res) => {
        commit('setListAvaliable', res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async createScheduling({ commit }, data) {
    await axios
      .post(`/${baseURL}`, data)
      .then((res) => {
        commit('pushScheduling', res.data)
        // console.log(data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getIntervalType({ commit }, typeId) {
    return await axios
      .get(`/${baseURL}-interval/${typeId}`)
      .then((res) => {
        commit('setType', res.data)
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getScheduling({ commit }) {
    return await axios
      .get(`/${baseURL}`)
      .then((res) => {
        const schedules = res.data
        if (schedules) {
          commit('pushScheduling', schedules[0])
        }
        commit('showAlert', !!schedules[0])

        return Promise.resolve(res.data)
        // console.log(data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async cancelScheduling({ commit, state }) {
    try {
      await axios.post(`/${baseURL}-cancel`, { ids: [state.schedules?.id] })
      commit('showAlert', false)
      commit('pushScheduling', null)
      return Promise.resolve(null)
    } catch (error) {
      console.log(error)
      return Promise.reject()
    }
  },
  async getSchedulingByType({ commit }, typeId) {
    return await axios
      .get(`/${baseURL}`)
      .then((res) => {
        let schedules = res.data
        schedules = schedules.filter((item) => item.typeId == typeId)
        if (schedules) {
          commit('pushScheduling', schedules[0])
        }
        commit('showAlert', !!schedules[0])
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
