<template>
  <v-tooltip right class="k-bullet-info">
    <template v-slot:activator="{ on }">
      <a target="_blank" :href="href" style="text-decoration: none" v-on="on">
        <v-icon> {{ icon }}</v-icon>
      </a>
    </template>
    <span>Clique para saber mais.</span>
  </v-tooltip>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    href: {
      require: true,
      type: String,
    },
    icon: {
      require: false,
      type: String,
      default: () => {
        return 'info'
      },
    },
  },
}
</script>
<style lang="sass">
body .application
  .k-bullet-info i
    color: #728498
    font-size: 20px
</style>
