<template>
	<v-img :src="path"></v-img>
</template>
<script>
import noEmployer from '@src/assets/images/icons/serves/icone-sem-funcionarios-registrados.png'
export default {
	data() {
		return {
			path: noEmployer
		}
	},
}
</script>