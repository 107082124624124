<template>
  <div class="d-flex align-center">
    <v-btn @click="dialog = true" icon>
      <v-icon>
        description
      </v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="600px" class="k-info-icon">
      <v-card v-if="dialog">
        <v-card-title v-if="modalTitle">
          <span class="headline">{{ modalTitle }}</span>
        </v-card-title>
        <slot></slot>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text="text" @click="agreeButton">
            {{ buttonLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-checkbox
      ref="chkAgree"
      v-model="agree"
      :label="label"
      :rules="rules"
    ></v-checkbox>
  </div>
</template>
<script>
export default {
  name: 'k-agree-checkbox',
  props: {
    value: { require: true, type: Boolean },
    label: {
      require: true,
      type: String,
    },
    buttonLabel: {
      type: String,
      default: 'Concordo',
    },
    modalTitle: {
      require: false,
      type: String,
    },
    rules: {
      require: true,
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    agree: {
      get() {
        return this.value
      },
      set(newValeu) {
        this.$emit('input', newValeu)
      },
    },
  },
  methods: {
    agreeButton() {
      this.dialog = false
      this.agree = true
      setTimeout(() => {
        this.$refs.chkAgree.validate()
      }, 1)
    },
  },
}
</script>
<style lang="sass"></style>
