import axios from '@plugins/axios'
import PaymentInfoModel from '@/models/PaymentInfo'
import { USER_REGISTRATION_STEP_PAYMENT } from '@state/modules/auth'

export const STEP_PAYMENT_INFO = 1
export const STEP_PAYMENT_RESUME = 2
export const STEP_PAYMENT_PENDING = 3
export const STEP_PAYMENT_FAIL = 4
export const TYPE_CREDITCARD = 1
export const TYPE_BOLETO = 2

const baseURL = 'payments-info'

export function getData() {
  return Object.assign({}, PaymentInfoModel)
}

const state = {
  paymentStep: {
    stepPaymentInfo: STEP_PAYMENT_INFO,
    stepPaymentResume: STEP_PAYMENT_RESUME,
    stepPaymentPanding: STEP_PAYMENT_PENDING,
    stepPaymentFail: STEP_PAYMENT_FAIL,
  },
  loading: true,
  loadingCep: false,
  loadingCreatePayment: false,
  loadingPlan: false,
  data: Object.assign({}, getData()),
  paymentErrorMessage: '',
  paymentError: false,
  paymentList: {},
  firstPaymentSummary: { items: [], total: null },
}

const getters = {
  companyPaymentInfo: (state) => state.data,
  companyPaymentPedingPayment(state, getters, rootState) {
    return (
      rootState.auth.currentUser.idStepRegistration ===
        USER_REGISTRATION_STEP_PAYMENT &&
      state.data.step.value === STEP_PAYMENT_PENDING
    )
  },
  companyPaymentShowInfo: (state, getters, rootState) => {
    return (
      rootState.auth.currentUser.idStepRegistration ===
        USER_REGISTRATION_STEP_PAYMENT &&
      state.data.step.value === STEP_PAYMENT_INFO
    )
  },
  companyPaymentShowBillDetails(state, getters, rootState) {
    return (
      rootState.auth.currentUser.idStepRegistration ===
        USER_REGISTRATION_STEP_PAYMENT &&
      state.data.step.value === STEP_PAYMENT_RESUME
    )
  },
  companyPaymentPedingPaymentCreditcard(state, getters, rootState) {
    return (
      rootState.auth.currentUser.idStepRegistration ===
        USER_REGISTRATION_STEP_PAYMENT &&
      state.data.step.value === STEP_PAYMENT_PENDING &&
      state.data.paymentMethod.value === TYPE_CREDITCARD
    )
  },
  companyPaymentPedingPaymentBoleto(state) {
    return (
      state.data.step.value === STEP_PAYMENT_PENDING &&
      state.data.paymentMethod.value === TYPE_BOLETO
    )
  },
  companyPaymentPedingPaymenCreditCard(state) {
    return (
      state.data.step.value === STEP_PAYMENT_PENDING &&
      state.data.paymentMethod.value === TYPE_CREDITCARD
    )
  },
  companyPaymentFailBoleto(state, getters, rootState) {
    return (
      rootState.auth.currentUser.idStepRegistration ===
        USER_REGISTRATION_STEP_PAYMENT &&
      state.data.step.value === STEP_PAYMENT_FAIL &&
      state.data.paymentMethod.value === TYPE_BOLETO
    )
  },
  companyPaymentFailCreditcard(state, getters, rootState) {
    return (
      rootState.auth.currentUser.idStepRegistration ===
        USER_REGISTRATION_STEP_PAYMENT &&
      state.data.step.value === STEP_PAYMENT_FAIL &&
      state.data.paymentMethod.value === TYPE_CREDITCARD
    )
  },
  companyPaymentInfoLoadingCep(state) {
    return state.loadingCep
  },
  companyPaymentInfoLoadingCreate(state) {
    return state.loadingCreatePayment
  },
  companyPaymentInfoLoadingPlan(state) {
    return state.loadingPlan
  },
  companyPaymentInfoLoadingCreateWithBoleto(state) {
    const boleto = state.data.paymentMethod.value === TYPE_BOLETO
    return state.loadingCreatePayment && boleto
  },
  companyPaymentInfoLoadingCreateWithCreditCard(state) {
    const creditcard = state.data.paymentMethod.value === TYPE_CREDITCARD
    return state.loadingCreatePayment && creditcard
  },
  companyPaymentInfoCepNotFound(state) {
    return state.data.cepNotFound
  },
  totalPlan(state) {
    return state.totalPlan
  },
  stepPaymentInfo(state) {
    return state.paymentStep.stepPaymentInfo
  },
  stepPaymentResume(state) {
    return state.paymentStep.stepPaymentResume
  },
  paymentErrorMessage(state) {
    return state.paymentErrorMessage
  },
  paymentError(state) {
    return state.paymentError
  },
  paymentListData: (state) => state.paymentList,
  checkIfPlanIsFree(state) {
    if (!state.data.currentSubscription?.plan) return false
    return (
      state.data.currentSubscription?.plan.price === 0 &&
      state.data.currentSubscription?.plan.classification === 'free'
    )
  },
  firstPaymentSummary: (state) => state.firstPaymentSummary,
}

const actions = {
  async getPaymentData({ commit, dispatch }) {
    return await axios
      .get(`/${baseURL}-user`)
      .then((res) => {
        const paymentInfo = res.data
        commit('setPaymentData', paymentInfo)
        commit('setPaymentInfo', Object.assign({}, getData(), res.data))
        dispatch(
          'planChangeNotification/getPlanDesiredChange',
          {},
          { root: true }
        )

        return Promise.resolve(paymentInfo)
      })
      .catch((error) => {
        console.log(error)
        return Promise.reject(error)
      })
  },
  async saveCompanyPaymentInfoField({ commit, state }, data) {
    return await axios
      .put(`${baseURL}/${state.data.id}`, data)
      .then(async (resp) => {
        const paymentInfo = Object.assign({}, getData(), resp.data)
        commit('updatePaymentInfo', {
          newValue: paymentInfo,
          keys: Object.keys(data),
        })

        return Promise.resolve(paymentInfo)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  async getPaymentInfoByRegistering({ commit, state }) {
    return await axios
      .get(`${baseURL}/registering`)
      .then((res) => {
        const paymentInfo = Object.assign({}, getData(), res.data)
        commit('setPaymentInfo', paymentInfo)
        return Promise.resolve(paymentInfo)
      })
      .catch((err) => {
        state.loading = false
        return Promise.reject(err)
      })
  },
  async getPaymentStep({ commit, dispatch }) {
    await axios
      .get(`${baseURL}/step`)
      .then((res) => {
        commit('setCompanyPaymentsInfoStep', res.data.paymentsStep)
        dispatch('auth/fetchUser', null, { root: true })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async createPayment({ state, commit, dispatch }, data) {
    try {
      if (state.loadingCreatePayment) return Promise.resolve(null)
      state.loadingCreatePayment = true
      await dispatch('saveCompanyPaymentInfoField', data)
      const resp = await axios.post('payments/create')
      const dataResp = resp.data
      await dispatch('getPaymentStep')
      commit('updatePaymentInfo', { newValue: dataResp, keys: ['link'] })
      state.loadingCreatePayment = false
      return Promise.resolve(dataResp)
    } catch (error) {
      state.loadingCreatePayment = false
      let response = error.response
      if (!Object.prototype.hasOwnProperty.call(response, 'data'))
        return Promise.reject(error)
      if (!Object.prototype.hasOwnProperty.call(response.data, 'message'))
        return Promise.reject(error)
      if (response.data.errors.includes('Coupon is invalid')) {
        dispatch('getPaymentInfoByRegistering')
        commit('errorCoupon')
      }
      return Promise.reject(error)
    }
  },
  async applyDiscount({ commit }, code) {
    try {
      const resp = await axios.post('payments/discout', { code: code })
      commit('setFirstPaymentSummary', resp.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  setCompanyPaymentsInfoLoadingCep({ commit, dispatch }, newValue) {
    commit('setLoadingCep', newValue)
  },
  async nextStepFreePlan({ commit, dispatch }) {
    return await axios
      .post('payments/free-plan')
      .then((res) => {
        commit('setCompanyPaymentsInfoStep', res.data.paymentsStep)
        dispatch('auth/fetchUser', null, { root: true })

        return Promise.resolve(true)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(true)
      })
  },
  async selectPlan({ commit, dispatch }, { plan_id, current_price_id }) {
    return await axios
      .put('payments-info/select-plan', { plan_id, current_price_id })
      .then((res) => {
        commit('setCompanyPaymentsInfoStep', res.data.paymentsStep)
        dispatch('auth/fetchUser', null, { root: true })

        return Promise.resolve(true)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(true)
      })
  },
  async getFirstPaymentSummary({ commit, dispatch }) {
    return await axios
      .get('payments-info-summary')
      .then((res) => {
        commit('setFirstPaymentSummary', res.data)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(true)
      })
  },
}

const mutations = {
  setPaymentData(state, data) {
    state.paymentList = data
    return state.paymentList
  },
  setCompanyPaymentsInfoLoadingCep(state, loadingCep) {
    state.loadingCep = loadingCep
  },
  setCompanyPaymentsInfoCepNotFound(state, notFound) {
    state.data.cepNotFound = notFound
  },
  setCompanyPaymentsInfoStep(state, step) {
    state.data.step.value = step
  },
  errorCoupon(state) {
    state.paymentErrorMessage = 'Cupom não é valido, confira os valores acima'
    state.paymentError = true
  },
  setLoadingCep(state, newValue) {
    state.loadingCep = newValue
  },
  updatePaymentInfo(state, { newValue, keys }) {
    keys.forEach((key) => {
      switch (key) {
        case 'cep':
          state.data.address.cep = newValue.address.cep
          state.data.address.address = newValue.address.address
          state.data.address.district = newValue.address.district
          state.data.address.state = newValue.address.state
          state.data.address.city = newValue.address.city
          break
        case 'step':
          state.data.step.value = newValue.step.value
          break
        case 'idPaymentsType':
          state.data.paymentMethod.value = newValue.paymentMethod.value
          break
        default:
          state.data[key] = newValue[key]
          break
      }
    })
  },
  setPaymentInfo(state, data) {
    state.loading = false
    state.data = data
  },
  setFirstPaymentSummary(state, data) {
    state.firstPaymentSummary.items = data
    state.firstPaymentSummary.total = data.reduce((accumulator, item) => {
      return accumulator + item.value * item.quantity
    }, 0)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
