import axios from '@plugins/axios'
import { Promise } from 'core-js'

export const CITY_SP_ID = 5346
const baseURL = 'state'
const CancelToken = axios.CancelToken

const state = {
  loading: false,
  city: {},
  cities: [],
  cancelReuest: null,
}

const getters = {
  city: (state) => state.city,
  cities: (state) => state.cities,
  citiesLoading: (state) => state.loading,
}

const actions = {
  async getCities({ commit, state }, params) {
    if (!params.stateId || state.loading) return
    commit('CITIES_LOADING_START')
    await axios
      .get(`${baseURL}/${params.stateId}/cities`)
      .then((res) => {
        commit('SET_CITIES', res.data)
        commit('CITIES_LOADING_END')
      })
      .catch((err) => {
        commit('CITIES_LOADING_END')
        console.log(err)
      })
  },
  async getAllCities({ commit, state }, query = '') {
    if (!!state.cancelReuest) state.cancelReuest()
    await axios
      .get(`cities/${query}`, {
        cancelToken: new CancelToken((c) => (state.cancelReuest = c)),
      })
      .then((res) => {
        commit('setCities', { cities: res.data })
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  async serchCityByStateAndName({ commit, state, dispatch }, { uf, city }) {
    return await dispatch('getAllCities', `?uf-state=${uf}&city=${city}`)
  },
  async getCity({ commit, state }, id) {
    await axios
      .get(`citie/${id}`)
      .then((res) => {
        commit('setCity', res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async getCityByStateName({ commit }, stateName) {
    await axios
      .get(`citie-by-state-name/${stateName}`)
      .then((res) => {
        commit('SET_CITIES', res.data)
        commit('CITIES_LOADING_END')
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        commit('CITIES_LOADING_END')
        console.log(err)
        return Promise.reject(err)
      })
  },
}

const mutations = {
  SET_CITIES(state, newValues) {
    state.cities = newValues
  },
  CITIES_LOADING_START(state) {
    state.loading = true
  },
  CITIES_LOADING_END(state) {
    state.loading = false
  },
  setCities(state, data) {
    state.cities = data.cities
  },
  setCity(state, data) {
    state.city = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
