/**
 *  ATTENTION! ATTENTION! ATTENTION! ATENÇÃO,
 *  Aandag, الاهتمام, Внимание, 注意, Pozor
 *  Aandacht. Huomiota, Aufmerksamkeit, Προσοχή,
 *  Atansyon, 주의, توجه, Uwagi, Atenţia, Внимание,
 *  Пажња, Pozornosť, Atención, Dikkat, Увага
 *
 *  Do not, I Repeat, Do not save Creditcard informations on Localstorage, DOM,
 *  IndexDB or anyother place, do not save IUGU tokens as well.
 */

import creditcard from '@src/services/creditcard'

function fields() {
  return {
    cdNumber: null,
    cvv: null,
    exp: null,
    name: null,
  }
}

function errors() {
  return {
    ccError: null,
    cvvError: null,
    expError: null,
    nameError: null,
  }
}

function setError(state, noError, target) {
  state.errors[target] = noError ? null : 'k-iugu-error'
}

function validate(state) {
  const okCdNumber = state.fields.cdNumber
    ? creditcard.validateNumber(state.fields.cdNumber)
    : null
  const okCvv = state.fields.cvv
    ? creditcard.validateCvv(state.fields.cdNumber, state.fields.cvv)
    : null
  const okExp = state.fields.exp
    ? creditcard.validateExpiration(state.fields.exp)
    : null
  const okName = !!/[\w]{3,}[\s]*?[\w]{3,}/.test(state.fields.name)

  setError(state, okCdNumber, 'ccError')
  setError(state, okCvv, 'cvvError')
  setError(state, okExp, 'expError')
  setError(state, okName, 'nameError')

  return okCdNumber && okCvv && okExp && okName
}

const state = {
  errors: errors(),
  fields: fields(),
}

const getters = {}

const actions = {
  async genToken(
    { commit, state },
    { number, month, year, firstName, lastName, cvv }
  ) {
    const iuguCreditCard = creditcard.objectCreditCard({
      number,
      month,
      year,
      firstName,
      lastName,
      cvv,
    })
    const iuguToken = await creditcard.createCreditcardToken(iuguCreditCard)
    return Promise.resolve(iuguToken)
  },
}

const mutations = {
  reset(state) {
    state.errors = errors()
    state.fields = fields()
  },
  cleanErrors(state) {
    state.errors = errors()
  },
  cleanFields(state) {
    state.fields = fields()
  },
  iuguErrors(state, errors) {
    if (typeof errors.last_name !== 'undefined')
      setError(state, false, 'nameError')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
