const state = {
  service: false,
}

const getters = {
  service: (state) => state.service,
}

const mutations = {
  serviceState(state, value) {
    state.service = value
  },
}

const actions = {}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
