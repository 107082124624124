import locale from './locale/pt-BR'
import filter from './filter/pt-BR'

export const translator = function(keyword, value) {
  if (typeof locale[keyword] !== 'undefined')
    return typeof locale[keyword] === 'function'
      ? locale[keyword](value)
      : locale[keyword]

  return keyword
}

export default {
  install: function(Vue, options) {
    Vue.prototype.$translator = translator
    for (var f in filter) Vue.filter(f, filter[f])
  },
}
