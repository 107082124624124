const state = {
  additionalService: false,
  issueInvoice: false,
  newExpense: false,
  useTerms: false,
  detailService: {},
  aproveService: {},
  detailServiceState: false,
  aproveServiceState: false,
  openningCompany: false,
  privacePolice: false,
  detailTax: false,
  tax: {},
  expense: {},
  delete: false,
  idDelete: null,
  videoShow: false,
  videoUrl: '',
}

const getters = {
  expense: (state) => state.expense,
  delete: (state) => state.delete,
  idDelete: (state) => state.idDelete,
  additionalService: (state) => state.additionalService,
  detailService: (state) => state.detailService,
  videoShow: (state) => state.videoShow,
}

const actions = {
  changeIssueInvoice({ commit }, status) {
    commit(
      'setIssueInvoice',
      status === undefined ? !this.state.dialogs.issueInvoice : status
    )
  },
  changeAdditionalService({ commit }, status) {
    commit(
      'setAdditionalService',
      status === undefined ? !this.state.dialogs.additionalService : status
    )
  },
  changeAproveService({ commit }, { status, service }) {
    status =
      status === undefined ? !this.state.dialogs.aproveServiceState : status
    service = service === undefined ? {} : service

    commit('setAproveService', { status, service })
  },
  changeDetailService({ commit }, { status, service }) {
    status =
      status === undefined ? !this.state.dialogs.detailServiceState : status
    service = service === undefined ? {} : service

    commit('setDetailService', { status, service })
  },
  changeNewExpense({ commit }, { status, expense }) {
    // console.log('b',expense);
    status = status === undefined ? !this.state.dialogs.newExpense : status
    expense = expense || {}

    commit('setNewExpense', { status, expense })
  },
  changeUseTerms({ commit }, status) {
    commit(
      'setUseTerms',
      status === undefined ? !this.state.dialogs.useTerms : status
    )
  },
  changeOpenningCompany({ commit }, status) {
    commit(
      'setOpenningCompany',
      status === undefined ? !this.state.dialogs.openningCompany : status
    )
  },
  changePrivacePolice({ commit }, status) {
    commit(
      'setPrivacePolice',
      status === undefined ? !this.state.dialogs.privacePolice : status
    )
  },
  changeDetailTax({ commit }, { status, tax }) {
    status === undefined ? !this.state.dialogs.detailTax : status,
      (tax = tax === undefined ? {} : tax)

    commit('setDetailTax', { status, tax })
  },
  changeVideoShow({ commit }, { status, url }) {
    status === undefined ? !this.state.dialogs.videoShow : status,
      (url = url === undefined ? '' : url)

    commit('setVideo', { status, url })
  },
}

const mutations = {
  setIssueInvoice(state, status) {
    state.issueInvoice = status
  },
  setUseTerms(state, status) {
    state.useTerms = status
  },
  setNewExpense(state, { status, expense }) {
    state.newExpense = status
    state.expense = expense
  },
  setAdditionalService(state, status) {
    state.additionalService = status
  },
  setAproveService(state, { status, service }) {
    state.aproveServiceState = status
    state.aproveService = service
  },
  setDetailService(state, { status, service }) {
    state.detailServiceState = status
    state.detailService = service
  },
  setOpenningCompany(state, status) {
    state.openningCompany = status
  },
  setPrivacePolice(state, status) {
    state.privacePolice = status
  },
  setDetailTax(state, { status, tax }) {
    state.detailTax = status
    state.tax = tax
  },
  setDelete(state, { status, id }) {
    state.delete = status
    state.idDelete = id
  },
  setVideo(state, { status, url }) {
    state.videoShow = status
    state.videoUrl = url
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
